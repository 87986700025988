import { GroupTwoTone, PersonTwoTone } from "@mui/icons-material";
import { Divider, ListItemButton, Skeleton } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./purchases-list.module.css";

export default function PurchasesList() {
  return (
    <DashCenterWrapper title="Bestellungen">
      <div className={style.main}>
        <PurchasesStats />
        <PurchasesListComponent />
      </div>
    </DashCenterWrapper>
  );
}
const PurchasesStatsStatus = {
  Loading: "loading",
  Normal: "normal",
  Error: "error",
};

function PurchasesStats() {
  const [status, setStatus] = useState(PurchasesStatsStatus.Loading);
  const [stats, setStats] = useState({ totalIncome: 0, totalSales: 0, totalUnits: 0 });
  useEffect(() => {
    async function loadStats() {
      const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/purchase/stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setStatus(PurchasesStatsStatus.Normal);

      if (response.status !== 200) {
        setStatus(PurchasesStatsStatus.Error);
        return;
      }

      const loadedStats = await response.json();

      if (loadedStats.totalIncome === "error" || loadedStats.totalSales === "error" || loadedStats.totalUnits === "error") {
        setStatus(PurchasesStatsStatus.Error);
        return;
      }

      setStats(loadedStats);
    }
    loadStats();
  }, [setStats, setStatus]);

  return (
    <div className={style.purchasesStats}>
      <PurchasesStatsCard title="Umsatz" status={status} value={(stats.totalIncome / 100).toFixed(2) + "€"} />
      <PurchasesStatsCard title="Verkäufe" status={status} value={stats.totalSales} />
      <PurchasesStatsCard title="Verkaufte Tickets" status={status} value={stats.totalUnits} />
    </div>
  );
}

function PurchasesStatsCard({ title, status, value }) {
  return (
    <div className={status === PurchasesStatsStatus.Error ? style.purchasesStatsCardError : style.purchasesStatsCard}>
      <h2>{title}</h2>
      {status !== PurchasesStatsStatus.Loading && <h1>{status === PurchasesStatsStatus.Error ? "Error" : value}</h1>}
      {status === PurchasesStatsStatus.Loading && <Skeleton variant="text" width={210} height={118} />}
    </div>
  );
}

/*
const PurchasesListStatus = {
  Loading: "loading",
  Normal: "normal",
};
*/

function PurchasesListComponent() {
  const navigate = useNavigate();
 // const [status, setStatus] = useState(PurchasesStatsStatus.Loading);
  const [purchases, setPurchases] = useState([]);
  useEffect(() => {
    async function loadPurchases() {
      const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/purchase`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status !== 200) {
        return;
      }
      setPurchases(await response.json());
     // setStatus(PurchasesStatsStatus.Normal);
    }
    loadPurchases();
  }, [setPurchases]);
  return (
    <div className={style.purchasesList}>
      <div className={style.purchasesListHeader}>
        <PersonTwoTone color="inherit" className={style.itemIcon} style={{ opacity: 0 }} />
        <h2>Name</h2>
        <h2>Bezahlt</h2>
        <h2>Status</h2>
        <h2 style={{ textAlign: "right" }}>Datum</h2>
      </div>
      {purchases
        .filter((purchase) => purchase.status !== "timeout")
        .map((purchase) => {
          return (
            <div key={purchase.id}>
              <Divider />
              <ListItemButton
                component="div"
                style={{ display: "block", padding: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("./" + purchase.id);
                }}
              >
                <div className={style.purchasesListItem}>
                  {purchase.amount === 1 && <PersonTwoTone color="inherit" className={style.itemIcon} />}
                  {purchase.amount !== 1 && <GroupTwoTone color="inherit" className={style.itemIcon} />}
                  <p>{purchase.firstName + " " + purchase.lastName}</p>
                  <p>{(purchase.price / 100).toFixed(2) + "€"}</p>
                  {purchase.status === "pending" && <p className={style.listStatusProcessing}>In Bearbeitung</p>}
                  {purchase.status === "done" && <p className={style.listStatusDone}>Erfolgreich</p>}
                  {purchase.status !== "done" && purchase.status !== "pending" && <p className={style.listStatusError}>Abgebrochen</p>}
                  <p style={{ textAlign: "right" }}>
                    {new Date(purchase.date).toLocaleString("de-DE", { day: "numeric", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })}
                  </p>
                </div>
              </ListItemButton>
            </div>
          );
        })}
      {purchases.length === 0 && (
        <>
          {" "}
          <Divider />
          <p className={style.emptyState}>Keine Bestellungen gefunden.</p>
        </>
      )}
    </div>
  );
}
