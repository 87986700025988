import { Link } from "react-router-dom";
import logo from "../assets/KeyTick.svg";
import Footer from "./footer";
import style from "./layout-fullpage-singel.module.css";

function LayoutFullPageSingel({ children, banner }) {
  return (
    <div>
      <header>
        <Link to="/">
          <img className={style.logo} src={logo} alt="logo" />
        </Link>
      </header>
      <main className={style.cardContainer}>
        <div className={banner ? style.bannerCard : style.card}>
          {banner && <div className={style.banner}>{banner}</div>}
          <div className={banner && style.bannerPadding}>{children}</div>
        </div>
      </main>
     <Footer />
    </div>
  );
}

export default LayoutFullPageSingel;
