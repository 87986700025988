import { Routes, Route } from "react-router-dom";
import PurchasesDetail from "./purchases-detail";
import PurchasesList from "./purchases-list";

export default function Purchases() {
  return (
    <Routes>
      <Route index element={<PurchasesList />} />
      <Route path=":purchasesID" element={<PurchasesDetail />} />
    </Routes>
  );
}
