import { Add, EditTwoTone, ErrorTwoTone, InfoTwoTone, SaveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Divider, Fab, Fade, FormControl, InputLabel, Link, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "../../../components/Switch";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./events-create.module.css";
import moment  from "moment";

export default function EventsCreate() {
  const navigate = useNavigate();
  //Values
  const [values, setValues] = useState({
    name: "",
    img: undefined,
    description: "",
    date: "",
    dateSaleStart: "",
    needAddress: false,
    coronaRule: "none",
    locationMaps: "",
    locationTitle: "",
    locationDescription: "",
    availible: 0,
    price: 0,
  });

  const [status, setStatus] = useState({ type: "", message: "" });

  const [isLoading, setLoading] = useState(false);

  const imgFileInput = useRef(null);

  const [images, setImages] = useState();

  const [update, setUpdate] = useState(false);

  const [oldEvent, setOldEvent] = useState();

  useEffect(() => {
    if (localStorage.getItem("editId") != null) {
      setUpdate(true);

      async function fetchEvent() {
        let response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/event/${localStorage.getItem("editId")}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        let temp = await response.json();
        setOldEvent(temp);
        console.log(temp);

//2024-11-TuT12:00

        let oldDateString = moment(temp.date).format("yyyy-MM-DD") + "T" + moment(temp.date).format("HH:mm");
        console.log(oldDateString);
        let oldSaleDateString = moment(temp.dateSaleStart).format("yyyy-MM-DD") + "T" + moment(temp.dateSaleStart).format("HH:mm");


        setValues({
          ...values,
          name: temp.name ?? "",
          img: undefined,
          description: temp.description ?? "",
          //yyyy-MM-ddThh:mm
          date: oldDateString ?? "",
          dateSaleStart: oldSaleDateString ?? "",
          needAddress: temp.needAddress ?? false,
          coronaRule: temp.coronaRule ?? "none",
          locationMaps: temp.location
            ? decodeURI(temp.location.gmaps.replace("https://maps.google.com/maps?q=", "").replace("&t=&z=10&ie=UTF8&iwloc=&output=embed", "")) ?? ""
            : "",
          locationTitle: temp.location ? temp.location.title ?? "" : "",
          locationDescription: temp.location ? temp.location.description ?? "" : "",
          availible: temp.availibleInital ?? 0,
          price: temp.price / 100 ?? 0,
        });
      }
      fetchEvent();
    }
  }, []);

  return (
    <DashCenterWrapper title={update ? "Event aktualisieren" : "Event erstellung"}>
      <div className={style.card}>
        <div className={style.content}>
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Title</h2>
              <p>Dies ist der Name des Events.</p>
            </div>
            <TextField
              id="event-title"
              label="Titel"
              type="text"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.name}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.name = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Event Banner</h2>
              <p>Wählen Sie einen Banner, welcher in Ihrem Shop für das Event angezeigt wird.</p>
            </div>
            <div
              className={style.settingImageHero}
              style={{
                backgroundImage: images ? `URL(${URL.createObjectURL(images)})` : "none",
              }}
            >
              <Fab
                color="primary"
                sx={{ boxShadow: "none" }}
                onClick={() => {
                  imgFileInput.current.click();
                }}
              >
                {images && <EditTwoTone />}
                {!images && <Add />}
              </Fab>
              <input
                ref={imgFileInput}
                onChange={(event) => {
                  setImages(event.target.files[0]);
                }}
                type="file"
                id="uploadImageHero"
                name="uploadImageHero"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
              ></input>
            </div>
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />

          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Beschreibung</h2>
              <p>Konfigurieren Sie Ihre Event Beschreibung. Die Beschreibung wird im Shop beim Event angezeigt.</p>
            </div>
            <TextField
              id="event-description"
              label="Beschreibung"
              type="text"
              sx={{ minWidth: "40%" }}
              variant="filled"
              multiline
              rows={10}
              value={values.description}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.description = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Event Start</h2>
              <p>Der Startzeitpunkt des Events.</p>
            </div>
            <TextField
              id="event-start"
              label="Event Start"
              type="datetime-local"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.date}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.date = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Verkauf Start</h2>
              <p>Der Startzeitpunkt des Verkaufs für dieses Event.</p>
            </div>
            <TextField
              id="event-sale-start"
              label="Verkauf Start"
              type="datetime-local"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.dateSaleStart}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.dateSaleStart = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />

          <div className={style.settingSwitch}>
            <div className={style.settingInfos}>
              <h2>Addresse Verlangen</h2>
              <p>Addresse beim Kaufprozess verlangen.</p>
            </div>
            <Switch
              checked={values.needAddress}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.needAddress = event.target.checked;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Corona Regeln</h2>
              <p>Welche Corona Regeln gelten für dieses Event.</p>
            </div>
            <FormControl variant="filled" sx={{ minWidth: "40%" }}>
              <InputLabel id="corona-rule-label">Corona Regeln</InputLabel>
              <Select
                labelId="corona-rule-label"
                id="corona-rule"
                value={values.coronaRule}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.coronaRule = event.target.value;
                  setValues(newValues);
                }}
              >
                <MenuItem value="none">Keine</MenuItem>
                <MenuItem value="2G+">2G+</MenuItem>
                <MenuItem value="2G">2G</MenuItem>
                <MenuItem value="3G+">3G+</MenuItem>
                <MenuItem value="3G">3G</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Standort Karte</h2>
              <p>Geben Sie eine grobe oder genaue Location für das Event an. Diese Location wird als Karte angezeigt.</p>
            </div>
            <TextField
              id="event-location-map"
              label="Standort Karte"
              type="text"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.locationMaps}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.locationMaps = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Standort Titel</h2>
              <p>Dieser Standort wird als Titel unter der Karte angezeigt.</p>
            </div>
            <TextField
              id="event-location-title"
              label="Standort Titel"
              type="text"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.locationTitle}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.locationTitle = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Standort Beschreibung</h2>
              <p>Diese Beschreibung wird unter dem Titel angezeigt.</p>
            </div>
            <TextField
              id="event-location-description"
              label="Standort Beschreibung"
              type="text"
              sx={{ minWidth: "40%" }}
              variant="filled"
              multiline
              rows={3}
              value={values.locationDescription}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.locationDescription = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>Anzahl an Tickets</h2>
              <p>Die Anzahl verfügbarer Tickets für diese Event.</p>
            </div>
            <TextField
              id="event-availible"
              label="Anzahl an Tickets"
              type="number"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.availible}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.availible = event.target.value;
                setValues(newValues);
              }}
            />
          </div>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <div className={style.settingTextfield}>
            <div className={style.settingInfos}>
              <h2>End Preis</h2>
              <p>
                Geben Sie den Preis an, welcher vom Kunden bezahlt wird. Für kostenlose Events geben Sie 0 ein. Sie müssen mindestens 0,50€ pro Ticket verlangen.
              </p>
              <div className={style.settingsBanner}>
                <InfoTwoTone />
                <p>
                  Bitte beachten Sie, dass dieser Preis Gebühren beinhaltet. Bis zu einem Ticket Preis von 4,50 Euro wird eine Gebühr von 0,50 Euro pro Verkauf
                  dem Ticket fällig. Ab einem Ticket Preis von 4,50 Euro wird eine Gebühr von 12,5 % des Verkaufspreises je verkauftem Ticket fällig.
                </p>
              </div>
            </div>
            <TextField
              id="event-price"
              label="Preis"
              type="number"
              sx={{ minWidth: "40%" }}
              variant="filled"
              value={values.price}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.price = event.target.value.replaceAll(",", ".");
                setValues(newValues);
              }}
            />
          </div>
        </div>
        <Divider />
        <div className={style.bottomBar}>
          <LoadingButton
            loading={isLoading}
            disableElevation
            loadingPosition="start"
            startIcon={<SaveTwoTone />}
            size="medium"
            variant="contained"
            onClick={async () => {
              setLoading(true);
              setStatus({ type: "", message: "" });
              //Validate all inputs
              if (!values.name) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte ein Titel vergeben" });
                return;
              }

              if (!images) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte ein Bild hinzufügen" });
                return;
              }

              if (!values.description) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte ein Beschreibung vergeben" });
                return;
              }

              if (!values.date) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte ein Event Startdatum vergeben" });
                return;
              }

              if (!values.dateSaleStart) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte ein Verkaufs Startdatum vergeben" });
                return;
              }

              if (!values.locationMaps) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte einen Standort für die Karte vergeben" });
                return;
              }

              if (!values.locationTitle) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte einen Karten Titel vergeben" });
                return;
              }

              if (!values.locationDescription) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte eine Standort Beschreibung vergeben" });
                return;
              }

              if (!values.availible) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte die Anzahl an verfügbaren Tickets angeben" });
                return;
              }

              if (values.availible <= 0) {
                setLoading(false);
                setStatus({ type: "error", message: "Bitte die Anzahl an verfügbaren Tickets angeben" });
                return;
              }

              if (Number(values.price) * 100 !== 0 && Number(values.price) * 100 - 50 < 0) {
                setLoading(false);
                setStatus({ type: "error", message: "Der Preis muss Mindesten 0,5€ betragen" });
                return;
              }

              //Transform img in Base64
              const modifiedValues = { ...values };
              modifiedValues.img = {};
              modifiedValues.img.base64 = (await toBase64(images)).split(",")[1];
              modifiedValues.img.suffix = images.name.split(".").pop();
              modifiedValues.img.type = images.type;

              //Transform Price
              modifiedValues.price = Number(values.price) * 100;

              //Fix Date Timezone
              modifiedValues.date = new Date(values.date).toISOString();
              modifiedValues.dateSaleStart = new Date(values.dateSaleStart).toISOString();

              if (localStorage.getItem("editId") != null) {
                let req = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/event/${localStorage.getItem("editId")}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify(modifiedValues),
                });
                if (req.status !== 200) {
                  setStatus({ type: "error", message: "Interner Server Fehler. Versuchen Sie es später erneut." });
                  setLoading(false);
                  return;
                }
              } else {
                let req = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/event`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify(modifiedValues),
                });
                if (req.status !== 201) {
                  setStatus({ type: "error", message: "Interner Server Fehler. Versuchen Sie es später erneut." });
                  setLoading(false);
                  return;
                }
              }
              navigate("/events", { replace: true });
              setLoading(false);
            }}
          >
            Speichern
          </LoadingButton>
          <Fade in={status.type === "info"} unmountOnExit mountOnEnter>
            <p className={style.infoStatus}>{status.message}</p>
          </Fade>
          <Fade in={status.type === "error"} unmountOnExit mountOnEnter>
            <div className={style.errorStatus}>
              <ErrorTwoTone color="inherit" />
              <p>{status.message}</p>
            </div>
          </Fade>
        </div>
      </div>
    </DashCenterWrapper>
  );
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
