import { CloseTwoTone, FileDownloadOutlined, FileDownloadTwoTone, FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Backdrop, Button, Divider, Fade, IconButton, InputAdornment, Modal, Skeleton, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./balance-view.module.css";

export default function BalanceView() {
  const [balance, setBalance] = useState({ income: "loading", volume: "loading", transactions: [] });
  const [dialogStatus, setDialogStatus] = useState({ open: false, error: "", value: "" });
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  async function loadBalance() {
    const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/balance/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) {
      return;
    }

    setBalance(await response.json());
  }

  useEffect(() => {
    loadBalance();
  }, []);

  return (
    <DashCenterWrapper title="Kontostand">
      <div className={style.main}>
        <div className={style.balanceStats}>
          <BalanceStatsCard title={"Aktueller Kontostand"} value={isNaN(balance.income) ? balance.income : (balance.income / 100).toFixed(2) + "€"} />
          <BalanceStatsCard title={"Umsatz"} value={isNaN(balance.volume) ? balance.volume : (balance.volume / 100).toFixed(2) + "€"} />
          <div className={style.balanceWithDrawCard}>
            <Button
              onClick={() => {
                setDialogStatus({ open: true, error: "", value: "" });
              }}
              size="large"
              variant="contained"
              disableElevation
              sx={{ height: "8rem" }}
            >
              Auszahlen
            </Button>
            <Modal
              open={dialogStatus.open}
              onClose={() => setDialogStatus({ open: false, error: "", value: "" })}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={dialogStatus.open}>
                <div className={style.dialogHolder}>
                  <div className={style.dialogBack}>
                    <div className={style.dialogCloseIcon}>
                      <h1>Auszahlung</h1>
                      <IconButton color="primary" onClick={() => setDialogStatus({ open: false, error: "", value: "" })}>
                        <CloseTwoTone />
                      </IconButton>
                    </div>
                    <TextField
                      type="number"
                      placeholder="0,00"
                      fullWidth
                      sx={{
                        margin: "1rem 0 1.5rem 0",
                      }}
                      helperText={dialogStatus.error}
                      label="Betrag"
                      value={dialogStatus.value}
                      onChange={(event) => {
                        let value = event.target.value;
                        let errorMsg = "";
                        if (value * 100 > Number(balance.income)) {
                          errorMsg = "Nicht genügend guthaben.";
                        } else {
                          errorMsg = "";
                        }
                        setDialogStatus({
                          open: true,
                          error: errorMsg,

                          value: value,
                        });
                      }}
                      error={dialogStatus?.error?.length > 0}
                      variant="filled"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      }}
                    />
                    <LoadingButton
                      onClick={async () => {
                        setLoadingWithdraw(true);
                        const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/balance/withdraw`, {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ amount: Math.round(Number(dialogStatus.value) * 100) }),
                        });

                        setLoadingWithdraw(false);
                        if (response.status !== 201) {
                          setDialogStatus({ open: true, error: "Interner Server error", value: dialogStatus.value });
                          return;
                        }

                        setDialogStatus({ open: false, error: "", value: "" });
                        loadBalance();
                      }}
                      size="large"
                      loading={loadingWithdraw}
                      variant="contained"
                      disableElevation
                      disabled={dialogStatus.value > Number(balance.income) || dialogStatus.value === 0}
                    >
                      Auszahlung anfodern
                    </LoadingButton>
                    <p className={style.dialogInfo}>
                      Die Auzahlung erfolgt, nach einer manuellen Prüfung, auf die von Ihnen angeben IBAN, welche bei der Erstellung Ihrer Organisation angegeben wurde. Es kann sein,
                      dass wir Sie vor der Auszahlung per E-Mail kontaktieren werden. Bei sonstigen Fragen oder Wünschen kontaktieren Sie den Support.
                    </p>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
        <div className={style.balanceList}>
          <div className={style.balanceListHeader}>
            <FileDownloadTwoTone color="inherit" className={style.itemIcon} style={{ opacity: 0 }} />
            <h2>Art</h2>
            <h2>Status</h2>
            <h2>Datum</h2>
            <h2 style={{ textAlign: "right" }}>Betrag</h2>
          </div>
          {balance.transactions.map((_balance) => {
            const balance = { income: _balance.income, date: _balance.date, status: "In Bearbeitung", type: "" };

            if (_balance.status === "done") {
              balance.status = "Abgeschlossen";
            }

            if (_balance.type === "cashout") {
              balance.type = "Auszahlung";
            }

            if (_balance.type === "event-buy") {
              balance.type = "Ticket Verkauf";
            }

            return (
              <div key={balance.date}>
                <Divider />
                <div className={style.balanceListItem}>
                  {balance.income >= 0 && <FileDownloadOutlined color="inherit" className={style.itemIcon} />}
                  {balance.income < 0 && <FileUploadOutlined color="inherit" className={style.itemIcon} />}
                  <p>{balance.type}</p>
                  <p>{balance.status}</p>
                  <p>
                    {new Date(balance.date).toLocaleString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "numeric",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p style={{ textAlign: "right" }}>
                    <span className={balance.income >= 0 ? style.addedInBalance : style.removeFromBalance}>{(balance.income / 100).toFixed(2) + "€"}</span>
                  </p>
                </div>
              </div>
            );
          })}
          {balance.transactions.length === 0 && (
            <>
              <Divider />
              <p className={style.emptyState}>Keine Kontobewegungen gefunden.</p>
            </>
          )}
        </div>
      </div>
    </DashCenterWrapper>
  );
}

function BalanceStatsCard({ title, value }) {
  return (
    <div className={value === "error" ? style.balanceStatsCardError : style.balanceStatsCard}>
      <h2>{title}</h2>
      {value !== "loading" && <h1>{value === "error" ? "Error" : value}</h1>}
      {value === "loading" && <Skeleton variant="text" width={210} height={118} />}
    </div>
  );
}
