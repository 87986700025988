import LayoutFullPageSingel from "../shared/layout-fullpage-singel";
import style from "./verification.module.css";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ErrorTwoTone, CheckCircleTwoTone } from "@mui/icons-material";

function Verification() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState({ status: "loading", msg: "Ihr KeyTick Konto wird verfiziert." });

  useEffect(() => {
    const token = searchParams.get("token");

    async function sendValidation() {
      try {
        setStatus({ status: "loading", msg: "Ihr KeyTick Konto wird verfiziert." });
        if (token) {
          let response = await fetch(process.env.REACT_APP_API + "/admin/account/verification", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: token }),
          });

          if (response.status === 200) {
            let data = await response.json();
            console.log(data);
            if (data.status === "error") {
              switch (data.code) {
                case 1:
                  setStatus({ status: "error", msg: "Der Link ist ungültig. Bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
                  break;
                case 2:
                  setStatus({ status: "error", msg: "Der Link ist abgelaufen. Bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
                  break;
                default:
                  setStatus({ status: "error", msg: "Fehler bei der Übertragung, bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
                  break;
              }
            }
            if (data.status === "succesful") {
              setStatus({ status: "succesful", msg: "Ihr KeyTick Konto wurde erfolgreich verfiziert. Sie werden gleich in kürze weitergeleitet." });
              setTimeout(() => {
                navigate(`/login`);
              }, 5000);
            }
          } else {
            setStatus({ status: "error", msg: "Fehler bei der Übertragung, bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
          }
        } else {
          setStatus({ status: "error", msg: "Fehler bei der Übertragung, bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
        }
      } catch (e) {
        console.error(e);
        setStatus({ status: "error", msg: "Fehler bei der Übertragung, bitte prüfen Sie den Link erneut oder konaktieren Sie den Support." });
      }
    }

    sendValidation();
  }, [searchParams, navigate]);

  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <h1 className={style.title}>Verifizierung</h1>
        <div className={style.progress}>
          {status.status === "loading" && <CircularProgress />}
          {status.status === "error" && <ErrorTwoTone sx={{ color: "#e63946", fontSize: 80 }} />}
          {status.status === "succesful" && <CheckCircleTwoTone sx={{ color: "#52b788", fontSize: 80 }} />}
        </div>
        <p className={style.info}>{status.msg}</p>
      </div>
    </LayoutFullPageSingel>
  );
}

export default Verification;
