import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import Dashboard from "./dashboard";
import Organisation from "./organisation";

function Home() {
  return (
    <Routes>
      <Route path="/organisations/*" element={<Organisation />} />
      <Route
        path="*"
        element={
          <RequireOrganisation>
            <Dashboard />
          </RequireOrganisation>
        }
      />
    </Routes>
  );
}

function RequireOrganisation({ children }) {
  let [hasOrganisationAccess, sethasOrganisationAcess] = useState(true);

  let organisationID = localStorage.getItem("organisation-id");
  let location = useLocation();

  useEffect(() => {
    async function checkAccessToOrganisation(orgID) {
      //Check if user has still access
      let response = await fetch(process.env.REACT_APP_API + "/admin/organisation", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let organisationList = await response.json();
      if(!organisationList.some((organisation) => organisation.organisationID === orgID)){
        sethasOrganisationAcess(false);
        localStorage.removeItem("organisation-id");
      }
    }

    if (hasOrganisationAccess) {
      if (!organisationID) {
        sethasOrganisationAcess(false);
        localStorage.removeItem("organisation-id");
      } else {
        checkAccessToOrganisation(organisationID);
      }
    }
  }, [hasOrganisationAccess, organisationID]);

  if (hasOrganisationAccess) {
    return children;
  } else {
    return <Navigate to="/organisations" state={{ from: location }} />;
  }
}

export default Home;
