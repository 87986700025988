import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./purchases-detail.module.css";

export default function PurchasesDetail() {
  const { purchasesID } = useParams();
  return (
    <DashCenterWrapper title="Bestellung">
      <div className={style.main}>
        <PurchasesDetailCard id={purchasesID} />
      </div>
    </DashCenterWrapper>
  );
}

function PurchasesDetailCard({ id }) {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});
  useEffect(() => {
    async function loadInfo() {
      const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/purchase/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLoading(false);
      if (response.status !== 200) {
        return;
      }

      setInfo(await response.json());
    }
    loadInfo();
  }, [setInfo, id, setLoading]);
  console.log(info.address);
  return (
    <div className={style.card}>
      {loading && <p>Wird geladen...</p>}
      {info.id && !loading && (
        <div className={style.detail}>
          <h1>Käufer</h1>
          <Divider />

          <table className={style.buyerInfo}>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{info.id}</td>
              </tr>
              <tr>
                <td>Event-ID</td>
                <td>{info.event}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{`${info.firstName} ${info.lastName}`}</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>{info.email}</td>
              </tr>
              {info.address && (
                <tr>
                  <td style={{verticalAlign: "baseline"}}>Addresse</td>
                  <td>{info.address.address}<br />{info.address.plz} {info.address.city}<br />{info.address.country}</td>
                </tr>
              )}
              <tr>
                <td>Verkaufte Tickets</td>
                <td>{info.amount}</td>
              </tr>
              <tr>
                <td>Preis</td>
                <td>{(info.price / 100).toFixed(2)}€</td>
              </tr>
              <tr>
                <td>Status</td>
                {info.status === "pending" && <td className={style.statusProcessing}>In Bearbeitung</td>}
                {info.status === "done" && <td className={style.statusDone}>Erfolgreich</td>}
                {info.status !== "done" && info.status !== "pending" && <td className={style.statusError}>Abgebrochen</td>}
              </tr>
            </tbody>
          </table>

          <h1>Teilnehmer</h1>
          <div className={style.responseivTable}>
            <div style={{ overflow: "hidden", minWidth: "40rem" }}>
              <Divider />
              <div className={style.membersList}>
                <h2>Name</h2>
                <h2>Telefon</h2>
                <h2>Geburtsdatum</h2>
                <h2 style={{ textAlign: "right" }}>Corona Status</h2>
              </div>
              {info.members.map((item) => {
                return (
                  <div key={item.firstName + item.lastName + item.birthday}>
                    <Divider />
                    <div className={style.membersList}>
                      <p>{`${item.firstName} ${item.lastName}`}</p>
                      <p>{item.phone}</p>
                      <p>{new Date(item.birthday).toLocaleDateString("de-DE", { day: "2-digit", month: "long", year: "numeric" })}</p>
                      <p style={{ textAlign: "right" }}>{item.coronaStatus}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {!info.id && !loading && <p>Bestellung nicht gefunden.</p>}
    </div>
  );
}
