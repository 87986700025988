import { Collapse, Divider, Fab, Fade, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Switch from "../../../components/Switch";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./store-edit.module.css";
import { Add, CheckCircleTwoTone, EditTwoTone, ErrorTwoTone, InfoTwoTone, SaveTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ColorPicker from "../components/color-picker";

function StoreEdit() {
  //Values
  const [values, setValues] = useState({
    active: false,
    assets: {},
    colorPrimary: "",
    description: "",
    messagePW: "",
    theme: "light",
    title: "",
    password: "",
    showAllEvents: true,
    hosttype: "keytick",
    domain: "",
    tokenKey: "",
  });

  //Download Values
  useEffect(() => {
    async function loadConfig() {
      let response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/store`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setValues(await response.json());
    }
    loadConfig();
  }, [setValues]);

  //Status of stroe config
  const [status, setStatus] = useState({ type: "info", message: "Keine Änderungen" });
  const [changedSettings, _setChangedSettings] = useState(false);
  function setChangedSettings(changed) {
    setStatus({ type: "none", message: "" });
    _setChangedSettings(changed);
  }
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const logoFileInput = useRef(null);
  const heroFileInput = useRef(null);

  const [images, setImages] = useState({
    imgLogo: undefined,
    imgHero: undefined,
  });

  return (
    <DashCenterWrapper title="Store">
      <div className={style.card}>
        <div className={style.content}>
          <div className={style.settingSwitch}>
            <div className={style.settingInfos}>
              <h2>Shop Aktivieren</h2>
              <p>Stellen Sie ein ob der Shop aktiv sein soll.</p>
            </div>
            <Switch
              checked={values.active}
              onChange={(event) => {
                //Set Value
                const newValues = { ...values };
                newValues.active = event.target.checked;
                setValues(newValues);
                //Set status to updatet value
                setChangedSettings(true);
              }}
            />
          </div>
          <Collapse in={values.active}>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Domain</h2>
                <p>
                  Lassen Sie Ihren "A" Eintrag auf <b>143.244.206.60</b> zeigen.
                </p>
                <div className={style.settingsBanner}>
                  <InfoTwoTone />
                  <p>
                    Noch keine Domain oder Schwierigkeiten die Domain einzurichten? <Link to="/support">Kontakieren Sie uns.</Link>{" "}
                  </p>
                </div>
              </div>
              <TextField
                id="store-domain"
                label="Domain"
                type="text"
                sx={{ minWidth: "40%" }}
                variant="filled"
                value={values.domain}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.domain = event.target.value;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Titel</h2>
                <p>Konfigurieren Sie Ihren Shop Titel. Der Titel wird in den Google Suche Ergebnissen und im Browser Tab angezeigt.</p>
              </div>
              <TextField
                id="store-title"
                label="Titel"
                type="text"
                sx={{ minWidth: "40%" }}
                variant="filled"
                value={values.title}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.title = event.target.value;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Beschreibung</h2>
                <p>Konfigurieren Sie Ihre Shop Beschreibung. Die Beschreibung wird in den Google Suche Ergebnissen angezeigt.</p>
              </div>
              <TextField
                id="store-description"
                label="Beschreibung"
                type="text"
                sx={{ minWidth: "40%" }}
                variant="filled"
                multiline
                rows={4}
                value={values.description}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.description = event.target.value;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Shop Logo</h2>
                <p>Wählen Sie das Logo, welches in Ihrem Shop angezeigt wird.</p>
              </div>
              <div
                className={style.settingImage}
                style={{
                  backgroundImage: images.imgLogo
                    ? `URL(${URL.createObjectURL(images.imgLogo)})`
                    : values.assets?.imgLogo
                    ? `URL(https://cdn.keytick.com/${values.assets.imgLogo})`
                    : "none",
                }}
              >
                <Fab
                  color="primary"
                  sx={{ boxShadow: "none" }}
                  onClick={() => {
                    logoFileInput.current.click();
                  }}
                >
                  {(images.imgLogo || values.assets?.imgLogo) && <EditTwoTone />}
                  {!images.imgLogo  && !values.assets?.imgLogo && <Add />}
                </Fab>
                <input
                  ref={logoFileInput}
                  onChange={(event) => {
                    console.log(event.target.files);
                    //Set Value
                    const newImages = { ...images };
                    newImages.imgLogo = event.target.files[0];
                    setImages(newImages);
                    //Set status to updatet value
                    setChangedSettings(true);
                  }}
                  type="file"
                  id="uploadImage"
                  name="uploadImage"
                  accept="image/png, image/jpeg, .svg"
                  style={{ display: "none" }}
                ></input>
              </div>
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Shop Banner</h2>
                <p>Wählen Sie das Banner, welches in Ihrem Shop angezeigt wird.</p>
              </div>
              <div
                className={style.settingImageHero}
                style={{
                  backgroundImage: images.imgHero
                    ? `URL(${URL.createObjectURL(images.imgHero)})`
                    : values.assets?.imgHero
                    ? `URL(https://cdn.keytick.com/${values.assets.imgHero})`
                    : "none",
                }}
              >
                <Fab
                  color="primary"
                  sx={{ boxShadow: "none" }}
                  onClick={() => {
                    heroFileInput.current.click();
                  }}
                >
                  {(images.imgLogo || values.assets?.imgHero) && <EditTwoTone />}
                  {!images.imgLogo  && !values.assets?.imgHero && <Add />}
                </Fab>
                <input
                  ref={heroFileInput}
                  onChange={(event) => {
                    console.log(event.target.files);
                    //Set Value
                    const newImages = { ...images };
                    newImages.imgHero = event.target.files[0];
                    setImages(newImages);
                    //Set status to updatet value
                    setChangedSettings(true);
                  }}
                  type="file"
                  id="uploadImageHero"
                  name="uploadImageHero"
                  accept="image/png, image/jpeg, .svg"
                  style={{ display: "none" }}
                ></input>
              </div>
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Shop Theme</h2>
                <p>Wählen Sie Ihr Shop Theme aus.</p>
              </div>
              <div className={style.settingTheme}>
                <ThemeDisplay
                  selected={values.theme === "light"}
                  onClick={() => {
                    //Set Value
                    const newValues = { ...values };
                    newValues.theme = "light";
                    setValues(newValues);
                    //Set status to updatet value
                    setChangedSettings(true);
                  }}
                  theme="light"
                  color={values.colorPrimary}
                />
                <ThemeDisplay
                  selected={values.theme === "dark"}
                  onClick={() => {
                    //Set Value
                    const newValues = { ...values };
                    newValues.theme = "dark";
                    setValues(newValues);
                    //Set status to updatet value
                    setChangedSettings(true);
                  }}
                  theme="dark"
                  color={values.colorPrimary}
                />
              </div>
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingSwitch}>
              <div className={style.settingInfos}>
                <h2>Shop Farbe</h2>
                <p>Wählen Sie die Haupt-Farbe Ihres Shop.</p>
              </div>
              <ColorPicker
                color={values.colorPrimary}
                onColorChange={(color) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.colorPrimary = color;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingSwitch}>
              <div className={style.settingInfos}>
                <h2>Alle Events anzeigen</h2>
                <p>Zeigen Sie auch bereits abgelaufene Events an.</p>
              </div>
              <Switch
                checked={values.showAllEvents}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.showAllEvents = event.target.checked;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Passwort</h2>
                <p>Dieses Passwort wird für den Zugang des Shops abgefragt.</p>
              </div>
              <TextField
                id="store-pw"
                label="Passwort"
                type="text"
                sx={{ minWidth: "40%" }}
                variant="filled"
                value={values.password}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.password = event.target.value;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
            <div className={style.settingTextfield}>
              <div className={style.settingInfos}>
                <h2>Passwort Beschreibung</h2>
                <p>Diese Beschreibung dient zur Erläuterung des Passworts.</p>
              </div>
              <TextField
                id="store-messagePW"
                label="Passwort Beschreibung"
                type="text"
                sx={{ minWidth: "40%" }}
                variant="filled"
                multiline
                rows={4}
                value={values.messagePW}
                onChange={(event) => {
                  //Set Value
                  const newValues = { ...values };
                  newValues.messagePW = event.target.value;
                  setValues(newValues);
                  //Set status to updatet value
                  setChangedSettings(true);
                }}
              />
            </div>
          </Collapse>
        </div>
        <Divider />
        <div className={style.bottomBar}>
          <LoadingButton
            loading={loadingUpdate}
            disableElevation
            disabled={!changedSettings}
            loadingPosition="start"
            startIcon={<SaveTwoTone />}
            size="medium"
            variant="contained"
            onClick={async () => {
              setLoadingUpdate(true);

              const modifiedValues = { ...values };
              modifiedValues.assets = {};

              //Add Logo
              if (images.imgLogo) {
                modifiedValues.assets.newLogo = (await toBase64(images.imgLogo)).split(',')[1];
                modifiedValues.assets.newLogoSuffix = images.imgLogo.name.split(".").pop();
                modifiedValues.assets.newLogoType = images.imgLogo.type;
              }

                //Add Hero
                if (images.imgHero) {
                    modifiedValues.assets.newHero = (await toBase64(images.imgHero)).split(',')[1];
                    modifiedValues.assets.newHeroSuffix = images.imgHero.name.split(".").pop();
                    modifiedValues.assets.newHeroType = images.imgHero.type;
                  }

                  
              let req = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/store`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(modifiedValues),
              });
              if (req.status !== 200) {
                setStatus({ type: "error", message: "Interner Server fehler. Versuchen Sie es später erneut." });
                setLoadingUpdate(false);
                return;
              }
              setStatus({ type: "info", message: "Änderungen gespeichert" });
              setLoadingUpdate(false);
              _setChangedSettings(false);
            }}
          >
            Speichern
          </LoadingButton>
          <Fade in={status.type === "info"} unmountOnExit mountOnEnter>
            <p className={style.infoStatus}>{status.message}</p>
          </Fade>
          <Fade in={status.type === "error"} unmountOnExit mountOnEnter>
            <div className={style.errorStatus}>
              <ErrorTwoTone color="inherit" />
              <p>{status.message}</p>
            </div>
          </Fade>
        </div>
      </div>
    </DashCenterWrapper>
  );
}

function ThemeDisplay({ selected, onClick, theme, color }) {
  return (
    <div
      onClick={onClick}
      className={style.themeSelectorContainer}
      style={{
        backgroundColor: theme === "light" ? "#f2f6fb" : "#0d1117",
        boxShadow: selected ? `0 0 0 0.3rem ${theme === "light" ? "#001e3c" : "#d0d0d0"}` : "0 0 0 0 #001e3c",
        transition: "all .15s ease",
      }}
    >
      <div className={style.themeSelectorCheck}>
        <CheckCircleTwoTone
          color="inherit"
          style={{ color: theme === "light" ? "#001e3c" : "#fff", opacity: selected ? "1" : "0", transition: "all .35s ease" }}
        />
      </div>
      <div className={style.themeSelectorModal} style={{ backgroundColor: theme === "light" ? "#ffffff" : "#161b22" }}>
        <div className={style.themeSelectorTextContainer}>
          <div className={style.themeSelectorTitle} style={{ backgroundColor: theme === "light" ? "#232323" : "#efefef" }}></div>
          <div className={style.themeSelectorText} style={{ backgroundColor: theme === "light" ? "#555555" : "#d4d4d4" }}></div>
          <div className={style.themeSelectorText} style={{ backgroundColor: theme === "light" ? "#555555" : "#d4d4d4" }}></div>
          <div className={style.themeSelectorText} style={{ backgroundColor: theme === "light" ? "#555555" : "#d4d4d4" }}></div>
        </div>
        <div className={style.themeSelectorButton} style={{ backgroundColor: color }}>
          <div className={style.themeSelectorButtonText}></div>
        </div>
      </div>
    </div>
  );
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default StoreEdit;
