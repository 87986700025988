import { Link } from "react-router-dom";
import style from "./footer.module.css";

function Footer() {
  return (
    <footer className={style.footer}>
      <span>© 2022 Myonit4o</span>
      <Link to="/support">Support</Link>
      <Link to="/legal/agb">AGB</Link>
      <Link to="/legal/impressum">Impressum</Link>
      <Link to="/legal/datenschutz">Datenschutz</Link>
    </footer>
  );
}

export default Footer;
