import { AddTwoTone, BusinessTwoTone } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import LayoutFullPageSingel from "../../shared/layout-fullpage-singel";
import style from "./list.module.css";

function OrganisationList() {
  const [organisations, setOrganisations] = useState();

  let navigate = useNavigate();
  let location = useLocation();
  let state = location.state;
  let from = state ? state.from.pathname : "/";

  useEffect(() => {
    async function retriveOrganisation() {
      let response = await fetch(process.env.REACT_APP_API + "/admin/organisation", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let orgs = await response.json();
      setOrganisations(orgs);
    }
    retriveOrganisation();
  }, [setOrganisations]);

  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <h1 className={style.title}>Organisationen</h1>

        <p className={style.content}>Bitte wählen Sie Ihre Organisation.</p>

        <div className={style.list}>
          {!organisations ? (
            <>
              <div className={style.item}>
                <Skeleton className={style.item} variant="rectangular" height={30} width={30} />
                <div className={style.itemContent}>
                  <Skeleton className={style.item} variant="text" height={5} width={170} />
                  <Skeleton className={style.item} variant="text" height={5} width={70} />
                </div>
              </div>
              <div className={style.item}>
                <Skeleton className={style.item} variant="rectangular" height={30} width={30} />
                <div className={style.itemContent}>
                  <Skeleton className={style.item} variant="text" height={5} width={170} />
                  <Skeleton className={style.item} variant="text" height={5} width={70} />
                </div>
              </div>
            </>
          ) : (
            <>
              {organisations &&
                organisations.map((org) => {
                  return (
                    <div
                      className={style.item}
                      key={org.organisationID}
                      onClick={() => {
                        localStorage.setItem("organisation-id", org.organisationID);
                        navigate(from, { replace: true });
                      }}
                    >
                      <BusinessTwoTone color="primary" className={style.itemIcon} />
                      <div className={style.itemContent}>
                        <h2 className={style.itemTitle}>{org.organisationName}</h2>
                        <h3 className={style.itemSubTitle}>{org.organisationEmail}</h3>
                      </div>
                    </div>
                  );
                })}
              <Link className={style.link} to="/organisations/create">
                <div className={style.item}>
                  <AddTwoTone color="primary" className={style.itemIcon} />
                  <div className={style.itemContent}>
                    <h2 className={style.itemTitle}>Eine neue Organisation erstellen</h2>
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>

        <p className={style.additional}>Falls Ihre Organisation bereits erstellt wurde und Sie diese nicht aufgelistet sehen: Bitte kontaktieren Sie den Ersteller der Organisation, damit dieser Ihnen Zugang gewährt.</p>
      </div>
    </LayoutFullPageSingel>
  );
}

export default OrganisationList;
