import { Popover } from "@mui/material";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import style from "./color-picker.module.css";

export default function ColorPicker({ color, onColorChange }) {
  const [colorContextMenu, setColorContextMenu] = useState(null);
  const open = Boolean(colorContextMenu);
  const id = open ? "color-popover" : undefined;
  return (
    <>
      <div
        onClick={(event) => {
          setColorContextMenu(colorContextMenu ? null : event.currentTarget);
        }}
        className={style.colorPickerButton}
        style={{ backgroundColor: color }}
      ></div>

      <Popover
        id={id}
        open={open}
        anchorEl={colorContextMenu}
        onClose={() => {
          setColorContextMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={style.colorPopUp}>
          <HexColorPicker color={color} onChange={onColorChange} />
        </div>
      </Popover>
    </>
  );
}
