import style from "./dash-center-wrapper.module.css";

export default function DashCenterWrapper({ title, end, children }) {
  return (
    <div className={style.wrapper}>
      <div className={style.layout}>
        {title && <h1 className={style.title}>{title}</h1>}
        <div>{end}</div>
      </div>
      {children}
    </div>
  );
}
