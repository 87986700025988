import LayoutFullPageSingel from "../shared/layout-fullpage-singel";
import SupportImage from "../assets/support.svg";
import style from "./support.module.css";
import { Alert } from "@mui/material";
import { InfoTwoTone } from "@mui/icons-material";

function Support() {
  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <img className={style.image} src={SupportImage} alt="logo" />

        <h1 className={style.title}>Support</h1>

        <Alert icon={<InfoTwoTone fontSize="inherit" />} severity="info">
          Wir antworten meistens außerhalb der üblichen Geschäftszeiten.
        </Alert>
        <p className={style.content}>
          Sie sind auf ein Problem gestoßen oder möchten ein Feature nutzen, welches noch nicht über die Oberfläche bedienbar ist? Sie können uns jederzeit
          gerne kontaktieren. Wir helfen Ihnen auch gerne Ihre KeyTick Experience einzurichten, oder diese nach Ihren Wünschen anzupassen.
        </p>

        <div className={style.contactInfos}>
          <div className={style.contact}>
            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" height="1.4rem" viewBox="0 0 24 24" fill="#001e3c">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 8l-8 5-8-5v10h16zm0-2H4l8 4.99z" opacity=".3" />
              <path d="M4 20h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2zM20 6l-8 4.99L4 6h16zM4 8l8 5 8-5v10H4V8z" />
            </svg>
            <div className={style.contactDetail}>
              <a href="mailto:support@keytick.com">support@keytick.com</a>
              <span>Antwortzeit ca. 72h</span>
            </div>
          </div>
          <div className={style.contact}>
            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="1.4rem" viewBox="0 0 24 24" fill="#001e3c">
              <g>
                <rect fill="none" height="24" width="24" y="0" />
              </g>
              <g>
                <g>
                  <g>
                    <path d="M19.05,4.91C17.18,3.03,14.69,2,12.04,2c-5.46,0-9.91,4.45-9.91,9.91c0,1.75,0.46,3.45,1.32,4.95L2.05,22l5.25-1.38 c1.45,0.79,3.08,1.21,4.74,1.21h0c0,0,0,0,0,0c5.46,0,9.91-4.45,9.91-9.91C21.95,9.27,20.92,6.78,19.05,4.91z M12.04,20.15 L12.04,20.15c-1.48,0-2.93-0.4-4.2-1.15l-0.3-0.18l-3.12,0.82l0.83-3.04l-0.2-0.31c-0.82-1.31-1.26-2.83-1.26-4.38 c0-4.54,3.7-8.24,8.24-8.24c2.2,0,4.27,0.86,5.82,2.42c1.56,1.56,2.41,3.63,2.41,5.83C20.28,16.46,16.58,20.15,12.04,20.15z M16.56,13.99c-0.25-0.12-1.47-0.72-1.69-0.81c-0.23-0.08-0.39-0.12-0.56,0.12c-0.17,0.25-0.64,0.81-0.78,0.97 c-0.14,0.17-0.29,0.19-0.54,0.06c-0.25-0.12-1.05-0.39-1.99-1.23c-0.74-0.66-1.23-1.47-1.38-1.72c-0.14-0.25-0.02-0.38,0.11-0.51 c0.11-0.11,0.25-0.29,0.37-0.43c0.12-0.14,0.17-0.25,0.25-0.41c0.08-0.17,0.04-0.31-0.02-0.43c-0.06-0.12-0.56-1.34-0.76-1.84 c-0.2-0.48-0.41-0.42-0.56-0.43C8.86,7.33,8.7,7.33,8.53,7.33c-0.17,0-0.43,0.06-0.66,0.31C7.65,7.89,7.01,8.49,7.01,9.71 c0,1.22,0.89,2.4,1.01,2.56c0.12,0.17,1.75,2.67,4.23,3.74c0.59,0.26,1.05,0.41,1.41,0.52c0.59,0.19,1.13,0.16,1.56,0.1 c0.48-0.07,1.47-0.6,1.67-1.18c0.21-0.58,0.21-1.07,0.14-1.18S16.81,14.11,16.56,13.99z" />
                  </g>
                </g>
              </g>
            </svg>
            <div className={style.contactDetail}>
              <a href="https://api.whatsapp.com/send?phone=4970316310766">+49 7031 6310766</a>
              <span>Antwortzeit ca. 24h</span>
            </div>
          </div>
        </div>
      </div>
    </LayoutFullPageSingel>
  );
}

export default Support;
