import { Routes, Route } from "react-router-dom";
import EventsCreate from "./events-create";
import EventsDetail from "./events-detail";
import EventsView from "./events-view";

export default function Events() {
  return (
    <Routes>
      <Route index element={<EventsView />} />
      <Route path="/create" element={<EventsCreate />} />
      <Route path=":eventID" element={<EventsDetail />} />
    </Routes>
  );
}
