import LayoutFullPageSingel from "../../shared/layout-fullpage-singel";

function TermsDash() {
  return (
    <LayoutFullPageSingel>
      <h1>Allgemein Geschäftsbedingungen (Händler)</h1>
      <h2>1. Kontobedingungen</h2>
      <ol>
        <li>
          Für den Zugriff auf die Services und deren Nutzung müssen Sie sich für ein KeyTick-Konto ("Konto") registrieren, indem Sie Ihren gesamten rechtmäßigen
          Namen, Ihre aktuelle Anschrift, Telefonnummer, eine gültige E-Mail-Adresse und andere als obligatorisch gekennzeichnete Informationen angeben. KeyTick
          kann nach eigenem Ermessen und aus beliebigem Grund Ihren Antrag auf ein Konto ablehnen oder ein vorhandenes Konto stornieren.
        </li>
        <li>Sie müssen mindestens 18 Jahre alt sein um ein Konto zu eröffnen.</li>
        <li>
          Sie bestätigen, dass Sie die von KeyTick bereitgestellten Services zum Zwecke der Ausübung einer Geschäftstätigkeit und nicht für persönliche,
          haushaltsbezogene oder familiäre Zwecke erhalten.
        </li>
        <li>
          Sie erkennen an, dass KeyTick die von Ihnen bei der Kontoeröffnung zur Verfügung gestellte oder zu einem späteren Zeitpunkt aktualisierte
          E-Mail-Adresse als primäre Kommunikationsmethode verwenden wird.
        </li>
        <li>
          Sie sind dafür verantwortlich, Ihr Kennwort sicher zu verwahren. KeyTick kann und wird nicht für Verluste oder Schäden haftbar gemacht werden, die
          durch Nichts Gewährleistung der Sicherheit Ihres Kontos und des Kennworts entstehen.
        </li>
        <li>
          Sie sind für alle Aktivitäten und Inhalte wie Fotos, Bilder, Videos, Grafiken, schriftliche Inhalte, Audiodateien, Code, Informationen oder Daten
          verantwortlich, die auf oder in Verbindung mit Ihrem Konto hochgeladen, erfasst, generiert, gespeichert, angezeigt, verbreitet, übertragen oder
          angezeigt werden ("Materialien").
        </li>
        <li>
          Der Verstoß gegen oder die Verletzung einer Bedingung dieser Allgemeinen Geschäftsbedingungen, einschließlich der Nutzungsbedingungen, führt nach
          alleinigem Ermessen von KeyTick zur sofortigen Kündigung Ihrer Services.
        </li>
      </ol>
      <h2>2. Kontoaktivierung</h2>
      <h3>2.1 Shop-Inhaber</h3>
      <ol>
        <li>
          Vorbehaltlich Abschnitt 2.1(2) ist die Person, die sich für den Service registriert, indem sie ein Konto eröffnet, im Sinne unserer Allgemeinen
          Geschäftsbedingungen die Vertragspartei ("Shop-Inhaber") und ist die Person, die zur Nutzung des entsprechenden Kontos berechtigt ist, das wir dem
          Shop-Inhaber in Verbindung mit dem Service zur Verfügung stellen können. Sie sind dafür verantwortlich, sicherzustellen, dass der Name des
          Shop-Inhabers (einschließlich des offiziellen Namens des Unternehmens, dem der Shop gehört, falls zutreffend) auf der Website des Shops deutlich
          sichtbar ist.
        </li>
        <li>
          Wenn Sie sich im Namen Ihres Arbeitgebers für die Services registrieren, ist Ihr Arbeitgeber der Shop-Inhaber. Wenn Sie sich im Namen Ihres
          Arbeitgebers für die Services registrieren, müssen Sie die von Ihrem Arbeitgeber bereitgestellte E-Mail-Adresse verwenden und erklären, dass Sie
          befugt sind, Ihren Arbeitgeber an unsere Allgemeinen Geschäftsbedingungen zu binden.
        </li>
        <li>
          Ihr KeyTick-Shop kann nur einem Shop-Inhaber zugewiesen sein. Ein KeyTick-Inhaber kann mehrere KeyTick-Shops haben. "Shop" bezieht sich auf den
          Onlineshop, der oder die mit dem Konto verbunden ist bzw. sind.
        </li>
      </ol>
      <h3>2.2 Domain-Namen</h3>
      <ol>
        <li>
          Mit dem Kauf eines Domain-Namens über KeyTick wird die Domainregistrierung so voreingestellt, dass sie jedes Jahr automatisch verlängert wird. Sie
          bestätigen, dass es in Ihrer alleinigen Verantwortung liegt, die automatische Verlängerung zu deaktivieren, wenn Sie dies wünschen.
        </li>
        <li>KeyTick behält sich vor ohne Angabe von gründe die Domain jederzeit zu entziehen.</li>
      </ol>
      <h2>3. Allgemeine Bedingungen</h2>
      <p>
        Sie müssen alle in diesen Allgemeinen Geschäftsbedingungen enthaltenen Bestimmungen lesen und akzeptieren, darunter die Nutzungsbedingungen und die
        Datenschutzerklärung, bevor Sie Mitglied von KeyTick werden können.
      </p>
      <ol>
        <li>Technischer Support für die Services wird nur KeyTick-Benutzern bereitgestellt.</li>
        <li>
          Diese Allgemeinen Geschäftsbedingungen unterliegen den Gesetzen der Bundesrepublik Deutschland und werden entsprechend interpretiert. Das
          Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf findet keine Anwendung auf diese Allgemeinen
          Geschäftsbedingungen und wird ausdrücklich ausgeschlossen.
        </li>
        <li>
          Sie erkennen an und stimmen zu, dass KeyTick diese Allgemeinen Geschäftsbedingungen jederzeit durch Veröffentlichen der entsprechend geänderten und
          angepassten Allgemeinen Geschäftsbedingungen auf der Website von KeyTick ändern kann, und dass solche Änderungen der Allgemeinen Geschäftsbedingungen
          ab dem Datum der Veröffentlichung gültig sind. Wenn Sie nach Veröffentlichung der geänderten Allgemeinen Geschäftsbedingungen auf der Website von
          KeyTick die Services weiterhin nutzen, gilt dies als Zustimmung und Annahme der geänderten Allgemeinen Geschäftsbedingungen durch Sie. Wenn Sie
          Änderungen an den Allgemeinen Geschäftsbedingungen nicht zustimmen, dürfen Sie die Services nicht mehr nutzen.
        </li>
        <li>
          Sie dürfen die Services von KeyTick weder für illegale oder nicht autorisierte Zwecke nutzen noch dürfen Sie bei der Nutzung der Services gegen
          Gesetze in Ihrem Land (einschließlich, aber nicht beschränkt auf Urheberrechtsgesetze), die im Land Ihres Kunden für Sie geltenden Gesetze oder die
          Gesetze der Bundesrepublik Deutschland verstoßen. Sie werden bei der Nutzung der Services und der Erfüllung Ihrer Verpflichtungen gemäß den
          Allgemeinen Geschäftsbedingungen alle geltenden Gesetze, Vorschriften und Bestimmungen einhalten.
        </li>
        <li>
          Die Lizenz- und Nutzungsbedingungen für die KeyTick-API unter https://www.keytick.de/legal/agb regeln Ihren Zugriff auf und Ihre Nutzung der
          KeyTick-API (wie darin definiert). Für die Aktivitäten unter Verwendung Ihrer API-Anmeldedaten (wie in den API-Bedingungen definiert) und für den
          Schutz Ihrer API-Anmeldedaten sind ausschließlich Sie verantwortlich.
        </li>
        <li>
          Sie verpflichten sich, ohne ausdrückliche schriftliche Genehmigung von KeyTick keinen Teil der Services, die Nutzung der Services oder den Zugriff
          darauf zu reproduzieren, zu vervielfältigen, zu kopieren, zu verkaufen, weiterzuverkaufen oder zu verwerten.
        </li>
        <li>
          Sie dürfen keine Suchmaschinen- oder anderen Pay-per-Click-Keywords (wie Google Ads) oder Domain-Namen erwerben, die KeyTick oder KeyTick-Marken
          und/oder Varianten und Rechtschreibfehler davon enthalten.
        </li>
        <li>Bei Fragen zu den Allgemeinen Geschäftsbedingungen wenden Sie sich bitte an den KeyTick Support.</li>
        <li>
          Sie nehmen zur Kenntnis, dass Ihre Materialien unverschlüsselt übertragen werden können und (a) Übertragungen über verschiedene Netzwerke sowie (b)
          Änderungen beinhalten können, um den technischen Anforderungen von verbundenen Netzwerken oder Geräten zu entsprechen und sich an diese anzupassen.
        </li>
        <li>
          Die Allgemeinen Geschäftsbedingungen können in anderen Sprachen als Deutsch vorliegen. Bei Unstimmigkeiten oder Widersprüchen zwischen den deutschen
          Allgemeinen Geschäftsbedingungen und den Allgemeinen Geschäftsbedingungen von KeyTick in einer anderen Sprache hat die jeweils aktuellste deutsche
          Version der Allgemeinen Geschäftsbedingungen Vorrang.
        </li>
        <li>
          Geschäftsbedingungen sind für die Vertragsparteien dieser Allgemeinen Geschäftsbedingungen und für ihre jeweiligen Erben, Rechtsnachfolger,
          zugelassenen Abtretungsempfänger und gesetzlichen Vertreter verbindlich. KeyTick ist berechtigt, diese Allgemeinen Geschäftsbedingungen ohne vorherige
          Mitteilung an Sie und ohne Ihre Einwilligung abzutreten. Sie sind nicht berechtigt, diese Allgemeinen Geschäftsbedingungen oder Ihre Rechte oder
          Pflichten aus diesen Allgemeinen Geschäftsbedingungen ohne vorherige schriftliche Zustimmung von KeyTick an Dritte abzutreten oder anderweitig zu
          übertragen, die nach alleinigem Ermessen von KeyTick erteilt oder verweigert werden kann.
        </li>
        <li>
          Sollte eine Bestimmung oder ein Teil der Bestimmung in diesen Allgemeinen Geschäftsbedingungen aus irgendeinem Grund als ungültig, rechtswidrig oder
          nicht durchsetzbar angesehen werden, so hat eine solche Ungültigkeit, Rechtswidrigkeit oder Undurchsetzbarkeit keine Auswirkungen auf andere
          Bestimmungen (oder den nicht betroffenen Teil der Bestimmung) der Allgemeinen Geschäftsbedingungen, und die Allgemeinen Geschäftsbedingungen werden so
          ausgelegt, als ob eine solche ungültige, rechtswidrige oder nicht durchsetzbare Bestimmung oder ein Teil der Bestimmung niemals Bestandteil der
          Allgemeinen Geschäftsbedingungen gewesen wäre.
        </li>
        <li>Alle Abschnitte bleiben auch nach Beendigung oder Ablauf dieser Allgemeinen Geschäftsbedingungen gültig.</li>
      </ol>
      <h2>4. Rechte von KeyTick</h2>
      <ol>
        <li>
          Wir behalten uns das Recht vor, die Services aus beliebigem Grund und ohne Ankündigung jederzeit zu ändern oder zu beenden. Nicht alle Services und
          Funktionen sind in allen Ländern verfügbar, und wir sind nicht verpflichtet, Services oder Funktionen in allen Ländern zur Verfügung zu stellen.
        </li>
        <li>Wir behalten uns das Recht vor, einer Person den Service jederzeit aus beliebigem Grund zu verweigern.</li>
        <li>
          Wir sind berechtigt, aber nicht verpflichtet, Materialien zu entfernen und Konten zu sperren oder zu kündigen, wenn wir nach eigenem Ermessen
          feststellen, dass die über einen Shop angebotenen Tickets oder die in einem Shop hochgeladenen oder veröffentlichten Materialien gegen unsere
          Nutzungsbedingungen oder diese Allgemeinen Geschäftsbedingungen verstoßen.
        </li>
        <li>
          Mündlicher oder schriftlicher Missbrauch jeglicher Art (u. a. Androhungen von Missbrauch oder Vergeltung) von KeyTick-Kunden, -Mitarbeitern,
          Mitgliedern oder Führungskräften führt zur sofortigen Kündigung des Kontos.
        </li>
        <li>
          Materialien werden von KeyTick nicht vorab geprüft und es liegt in unserem alleinigen Ermessen, Materialien zu verweigern oder aus dem Service zu
          entfernen, einschließlich Ihres Shops.
        </li>
        <li>
          Wir behalten uns das Recht vor, Ihren Wettbewerbern unsere Services zur Verfügung zu stellen, und geben in keinem bestimmten Marktsegment ein
          Exklusivitätsversprechen ab. Ferner erkennen Sie an und stimmen zu, dass KeyTick-Mitarbeiter und -Auftragnehmer auch Kunden/Händler von KeyTick sein
          können und dass diese mit Ihnen konkurrieren können, auch wenn sie dabei nicht Ihre vertraulichen Informationen (wie in Abschnitt 5 definiert)
          verwenden dürfen.
        </li>
        <li>
          Bei Streitigkeiten in Bezug auf das Kontoeigentum behalten wir uns das Recht vor, Unterlagen anzufordern, um das Kontoeigentum zu ermitteln oder zu
          bestätigen. Zu diesen Unterlagen zählen unter anderem eine eingescannte Kopie Ihrer Gewerbeerlaubnis, ein amtlicher Lichtbildausweis, Ihr Status als
          Mitarbeiter eines Unternehmens usw.
        </li>
        <li>
          KeyTick behält sich das Recht vor, nach eigener Einschätzung das rechtmäßige Kontoeigentum zu ermitteln und ein Konto auf den rechtmäßigen
          Shop-Inhaber zu übertragen. Wenn wir, unbeschadet unserer sonstigen Rechte und Rechtsmittel, den rechtmäßigen Kontoinhaber nicht ermitteln können,
          behält sich KeyTick das Recht vor, ein Konto vorübergehend zu deaktivieren, bis die Streitparteien zu einer Lösung gelangt sind.
        </li>
      </ol>
      <h2>5. Verschwiegenheitspflicht</h2>
      <ol>
        <li>
          "Vertrauliche Informationen" umfassen, sind jedoch nicht eingeschränkt auf alle Informationen, die mit den Geschäften einer Partei in Verbindung
          stehen und nicht öffentlich bekannt sind, einschließlich spezifischer Geschäftsinformationen, technischer Prozesse und Formeln, Software,
          Kundenlisten, Listen von potenziellen Kunden, Namen, Adressen und andere Informationen über Kunden und potenzielle Kunden, Produktdesigns, Verkäufe,
          Kosten (einschließlich jeglicher relevanten Bearbeitungsgebühren), Preislisten und andere unveröffentlichte Finanzinformationen, Geschäftspläne und
          Marketingdaten sowie sonstige vertrauliche und geschützte Informationen, unabhängig davon, ob sie als vertraulich oder geschützt gekennzeichnet sind.
          Zu den vertraulichen Informationen gehören alle Informationen, die Sie im Zusammenhang mit uns und den Services erhalten, die der allgemeinen
          Öffentlichkeit nicht bekannt sind, einschließlich Informationen zu unserem Sicherheitsprogramm und -praktiken.
        </li>
        <li>
          Jede Partei verpflichtet sich, die vertraulichen Informationen der anderen Partei nur so zu verwenden, wie dies zur Erfüllung ihrer Verpflichtungen
          gemäß diesen Allgemeinen Geschäftsbedingungen und in Übereinstimmung mit jeglichen anderen Verpflichtungen in diesen Allgemeinen Geschäftsbedingungen
          einschließlich dieses Abschnitts 6 erforderlich ist. Jede Partei erklärt sich damit einverstanden, alle zumutbaren Schritte zu unternehmen, die
          zumindest den Schritten zum Schutz ihrer eigenen geschützten Informationen im Wesentlichen entsprechen, um die Duplizierung, Offenlegung oder
          Verwendung solcher vertraulichen Informationen zu verhindern, außer (i) durch ihre Mitarbeiter, Vertreter und Unterauftragnehmer oder diesen
          gegenüber, die Zugang zu solchen vertraulichen Informationen haben müssen, um die Verpflichtungen dieser Partei gemäß dieser Vereinbarung zu erfüllen,
          die diese vertraulichen Informationen wie hier beschrieben behandeln und die dieser Partei gegenüber Verschwiegenheitspflichten haben, die mindestens
          so streng sind wie die hierin enthaltenen; oder (ii) wie in jeglichem Gesetz, jeglicher Verordnung oder Anordnung, deren ordentlicher Gerichtsbarkeit
          die Parteien und der in diesen Allgemeinen Geschäftsbedingungen enthaltene Gegenstand unterliegen, erforderlich ist, vorausgesetzt, dass die
          empfangende Partei, sofern gesetzlich zulässig, der offenlegenden Partei unverzüglich schriftlich Mitteilung macht und wirtschaftlich angemessene
          Anstrengungen unternimmt, um sicherzustellen, dass diese Offenlegung vertraulich behandelt wird. Vertrauliche Informationen dürfen keine Informationen
          enthalten, die die empfangende Partei nachweisen kann: (A) war zum Zeitpunkt der Offenlegung dieser Informationen bereits öffentlich bekannt oder war
          der empfangenden Partei bereits bekannt oder befand sich in deren Besitz ; (B) wird unabhängig von der empfangenden Partei herausgefunden, ohne die
          vertraulichen Informationen der anderen Partei zu verwenden oder auf diese Bezug zu nehmen und ohne gegen irgendwelche Bestimmungen in diesen
          Allgemeinen Geschäftsbedingungen zu verstoßen; oder (C) wird danach von der empfangenden Vertragspartei zu Recht von einer anderen Quelle als der
          offenlegenden Partei erhalten, ohne irgendeine Bestimmung dieser Allgemeinen Geschäftsbedingungen zu verletzen.
        </li>
      </ol>
      <h2>6. Haftungsbeschränkung</h2>
      <ol>
        <li>
          Sie nehmen ausdrücklich zur Kenntnis und stimmen ausdrücklich zu, dass KeyTick im gesetzlich zulässigen Umfang nicht für direkte, indirekte,
          zufällige, besondere, Folgeschäden oder beispielhafte Schäden haftet, einschließlich Schäden aus entgangenem Gewinn, Goodwill, Unmöglichkeit der
          Nutzung, Datenverlust oder anderen immateriellen Schäden, die durch die Nutzung oder die Unmöglichkeit der Nutzung des Service entstehen.
        </li>
        <li>
          Im gesetzlich zulässigen Umfang haften KeyTick unter keinen Umständen für entgangene Gewinne oder besondere, zufällige oder Folgeschäden, die durch
          oder in Verbindung mit unserer Website, unseren Services oder diesen Allgemeinen Geschäftsbedingungen entstehen (unabhängig von der Entstehungsart,
          einschließlich Fahrlässigkeit). Sie verpflichten sich, uns und (soweit anwendbar) unsere Muttergesellschaft, Tochtergesellschaften, Affiliates,
          KeyTick-Partner, leitenden Angestellten, Führungskräfte, Vertreter, Mitarbeiter in Bezug auf Ansprüche oder Forderungen (einschließlich angemessener
          Anwaltskosten) zu entschädigen und schadlos zu halten, die von Dritten aufgrund Ihres Verstoßes gegen diese Allgemeinen Geschäftsbedingungen oder die
          durch Verweis darin enthaltenen Dokumente (einschließlich der Nutzungsbedingungen) oder Ihres Verstoßes gegen Gesetze oder die Rechte Dritter oder im
          Zusammenhang damit erhoben werden.
        </li>
        <li>
          Sie verwenden die Services auf eigenes Risiko. Die Service werden "wie besehen" und "wie verfügbar" zur Verfügung gestellt, ohne ausdrückliche,
          stillschweigende oder gesetzliche Garantie oder Gewährleistung.
        </li>
        <li>KeyTick garantiert nicht, dass die Services unterbrechungsfrei, zeitnah, sicher oder fehlerfrei funktionieren.</li>
        <li>KeyTick garantiert nicht, dass die Ergebnisse, die mit der Nutzung der Services erzielt werden können, korrekt oder zuverlässig sind.</li>
        <li>
          KeyTick garantiert nicht, dass die Qualität von Produkten, Services, Informationen oder anderen Materialien, die von Ihnen über die Services gekauft
          oder erworben wurden, Ihren Erwartungen entspricht oder dass Fehler in den Services korrigiert werden.
        </li>
      </ol>
      <h2>7. Verzicht, Salvatorische Klausel und vollständige Vereinbarung</h2>
      <ol>
        <li>
          Das Versäumnis von KeyTick, ein Recht oder eine Bestimmung der Allgemeinen Geschäftsbedingungen geltend zu machen oder durchzusetzen, stellt keinen
          Verzicht auf dieses Recht bzw. diese Bestimmung dar. Wenn eine Bestimmung der Allgemeinen Geschäftsbedingungen, einschließlich aller Bestimmungen und
          Bedingungen und anderer Dokumente, die durch Verweis Bestandteil der Allgemeinen Geschäftsbedingungen sind, von einem zuständigen Gericht als
          gesetzeswidrig erachtet wird, so ist diese Bestimmung so zu ändern und auszulegen, dass die Ziele der ursprünglichen Bestimmung im größtmöglichen
          gesetzlich zulässigen Umfang am besten erreicht werden, und die verbleibende Regelung der Allgemeinen Geschäftsbedingungen bleibt in vollem Umfang in
          Kraft und wirksam.
        </li>
        <li>
          Die Allgemeinen Geschäftsbedingungen und die durch Verweis darin enthaltenen Dokumente stellen die gesamte Vereinbarung zwischen Ihnen und KeyTick dar
          und regeln Ihre Nutzung des Services und Ihres Kontos; sie ersetzen alle vorherigen Vereinbarungen zwischen Ihnen und KeyTick (unter anderem etwaige
          vorherige Versionen der Allgemeinen Geschäftsbedingungen).
        </li>
      </ol>
      <h2>8. Geistiges Eigentum und Inhalte von Kunden</h2>
      <ol>
        <li>
          Wir beanspruchen keine geistigen Eigentumsrechte an den Materialien, die Sie dem KeyTick-Service zur Verfügung stellen. Alle Materialien, die Sie
          hochladen, bleiben Ihr Eigentum. Sie können Ihren KeyTick-Shop jederzeit entfernen, indem Sie Ihr Konto löschen.
        </li>
        <li>
          Durch das Hochladen von Materialien stimmen Sie zu: (a) dass es anderen Internetnutzern gestattet ist, die von Ihnen in Ihrem Shop veröffentlichten
          Materialien anzusehen und aufzurufen; (b) dass KeyTick Ihre Materialien speichern und, falls Sie Materialien öffentlich gepostet haben, anzeigen und
          verwenden darf; und (c) dass KeyTick jederzeit alle an seinen Service übermittelten Materialien überprüfen und löschen kann, obwohl KeyTick dazu nicht
          verpflichtet ist.
        </li>
        <li>
          Sie behalten das Eigentum an allen Materialien, die Sie in den Shop hochladen; indem Sie Ihren Shop öffentlich machen, stimmen Sie jedoch zu, dass
          andere Personen das Material sehen und anzeigen dürfen, das Sie öffentlich in Ihrem Shop posten. Sie sind dafür verantwortlich, dass die Materialien
          allen geltenden Gesetzen oder Bestimmungen entsprechen.
        </li>
        <li>
          KeyTick hat das nicht-exklusive Recht und die Lizenz, die mit Ihrem Shop verbundenen Namen, Marken, Dienstleistungsmarken und Logos zu verwenden, um
          Werbung für den Service zu machen.
        </li>
      </ol>
      <h2>9. Zahlung der Gebühren</h2>
      <ol>
        <li>
          Alle ausgewiesen Gebühren, u. a. Gebühren im Zusammenhang mit dem Wert der über Ihren Shop erzielten Verkäufe, sowie Gebühren im Zusammenhang mit
          Ihrem Kauf oder Ihrer Nutzung von Produkten oder Services wie Domänennamen oder Services Dritter ("zusätzliche Gebühren"). Zusammen werden die
          Dienstleistungsgebühren von KeyTick, Transaktionsgebühren von den Zahlungsdienstleistern und die zusätzlichen Gebühren als die "Gebühren" bezeichnet.
        </li>
        <li>
          Sie müssen bei uns eine gültige Bankverbindung zur Bezahlung aller anfallenden und wiederkehrenden Gebühren hinterlegen. KeyTick belastet das von
          Ihnen autorisierte Bankkonto mit den jeweiligen Gebühren und wird weiterhin mit den jeweiligen Gebühren belasten, bis die Services beendet werden und
          alle ausstehenden Gebühren vollständig bezahlt wurden. Sofern nicht anders angegeben, sind alle Gebühren und sonstigen Kosten in Euro angegeben, und
          alle Zahlungen erfolgen in Euro.
        </li>
        <li>
          KeyTick behält sich vor nach kündigung der Lastschriftvereinbarung oder einer Rücklastschrift durch die Bank die Dienstleistungen ohne die weitere
          Angabe von gründen zu beenden. In diesem Fall werden alle offenen Forderungen in Rechnung gestellt.
        </li>
        <li>
          Transaktionsgebühren werden direkt beim verkauf vom Preis abgezogen. Benutzer haben etwa zwei Wochen Zeit, um Probleme mit der Abrechnung zu klären.
        </li>
        <li>
          KeyTick behält sich vor Rückbuchungen oder sonstige anfallende Gebühren dem Händler in Rechnung zu stellen und mittels Lastschrift (sofern
          Lastschriftvereinbarung vorliegend) abzubuchen.
        </li>
        <li>
          Wenn wir die Zahlung von Gebühren nicht mit einer autorisierten Zahlungsmethode verarbeiten können, versuchen wir drei Tage später erneut, die Zahlung
          mit irgendeiner autorisierten Zahlungsmethode zu verarbeiten. Sollte der zweite Versuch nicht erfolgreich sein, unternehmen wir drei Tage nach dem
          zweiten Versuch einen letzten Versuch. Ist unser letzter Versuch nicht erfolgreich, können wir den Zugriff auf Ihr Konto und die Services sperren und
          widerrufen. Ihr Konto wird wieder aktiviert, sobald Sie die ausstehenden Gebühren zuzüglich der Gebühren für den nächsten Rechnungszyklus bezahlt
          haben. Während des Sperrzeitraums können Sie nicht auf Ihr Konto oder Ihren Shop zugreifen. Wenn die ausstehenden Gebühren nach 60 Tagen ab dem Datum
          der Sperrung nicht bezahlt wurden, behält sich KeyTick das Recht vor, Ihr Konto zu kündigen.
        </li>
        <li>
          KeyTick berechnet die Steuern, die in der Bundesrepublik Deutschland gilt. Diese Beträge gelten zusätzlich zu den Gebühren für die jeweiligen Produkte
          und Services und werden automatisch von Verkaufspreis abgeführt.{" "}
        </li>
        <li>
          Um Missverständnisse auszuschließen: Alle Beträge, die Sie gemäß diesen Allgemeinen Geschäftsbedingungen an KeyTick zahlen müssen, sind ohne jegliche
          Abzüge oder Einbehaltungen zu zahlen. Abgesehen von Steuern, die KeyTick Ihnen berechnet und in Ihrem Namen an die zuständigen Steuerbehörden
          überweist, müssen alle gesetzlich vorgeschriebenen Abzüge oder Einbehaltungen von Ihnen getragen und separat an die jeweilige Steuerbehörde gezahlt
          werden. KeyTick ist berechtigt, Ihnen den vollen Betrag der Gebühren, die in diesen Allgemeinen Geschäftsbedingungen festgelegt sind, über Ihre
          autorisierte Zahlungsmethode zu berechnen, ohne dass ein solcher Abzug oder eine solche Einbehaltung erforderlich sind.
        </li>
        <li>KeyTick bietet keine Rückerstattungen an.</li>
      </ol>
      <h2>10. Stornierung und Kündigung</h2>
      <ol>
        <li>
          Sie können Ihr Konto jederzeit stornieren und die Allgemeinen Geschäftsbedingungen beenden, indem Sie den KeyTick Support kontaktieren und
          anschließend die Anweisungen in der Antwort von KeyTick befolgen.
        </li>
        <li>Bei Kündigung der Services durch eine Partei aus beliebigem Grund geschieht Folgendes:</li>
        <ol>
          <li>KeyTick stellt Ihnen die Services nicht mehr zur Verfügung und Sie können nicht mehr auf Ihr Konto zugreifen;</li>
          <li>
            sofern in den Allgemeinen Geschäftsbedingungen nichts anderes festgelegt ist, haben Sie keinen Anspruch auf Erstattung von Gebühren, ob anteilig
            oder anderweitig;
          </li>
          <li>
            ausstehende Beträge, die Sie KeyTick für Ihre Nutzung der Services bis zum Wirksamkeitsdatum dieser Kündigung schulden, werden sofort in voller Höhe
            fällig; und
          </li>
          <li>Ihre Shop-Website wird offline geschaltet.</li>
        </ol>
        <li>
          Wenn Sie über KeyTick einen Domain-Namen erworben haben, wird Ihre Domain nach der Kündigung nicht automatisch verlängert. Nach der Kündigung liegt es
          allein in Ihrer Verantwortung, alle Angelegenheiten im Zusammenhang mit Ihrer Domain mit dem Domain-Provider zu regeln.
        </li>
        <li>
          Wenn Sie KeyTick am Datum der Kündigung des Service noch ausstehende Gebühren schulden, erhalten Sie eine letzte Rechnung per E-Mail. Sobald diese
          Rechnung in voller Höhe bezahlt wurde, erhalten Sie keine weiteren Rechnungen.
        </li>
        <li>
          Wir behalten uns das Recht vor, den KeyTick-Service, die Allgemeinen Geschäftsbedingungen und/oder Ihr Konto jederzeit aus beliebigem Grund ohne
          Ankündigung zu ändern oder zu beenden (es sei denn, das geltende Recht schreibt etwas anderes vor). Die Kündigung der Allgemeinen Geschäftsbedingungen
          lässt Rechte und Pflichten unberührt, die vor dem Datum der Kündigung entstanden sind.
        </li>
        <li>
          Betrug: Ohne Einschränkung anderer Rechtsmittel kann KeyTick Ihr Konto sperren oder kündigen, wenn wir vermuten, dass Sie (durch Verurteilung,
          Abwicklung, Versicherung oder Untersuchung von Treuhandvermögen oder anderweitig) betrügerische Aktivitäten in Verbindung mit der Nutzung der Services
          ausgeführt haben.
        </li>
      </ol>
      <h2>11. Änderungen der Services und Preise</h2>
      <ol>
        <li>
          Die Preise für die Nutzung der Services können sofort durch Mitteilung von KeyTick geändert werden. Diese Mitteilung kann jederzeit durch
          Veröffentlichen der Änderungen auf der KeyTick-Website (keytick.com) mit einer Ankündigung erfolgen.
        </li>
        <li>
          KeyTick behält sich das Recht vor, die Services (oder einen Teil davon) jederzeit und gelegentlich mit oder ohne Ankündigung zu ändern oder
          einzustellen (es sei denn, das geltende Recht schreibt etwas anderes vor).
        </li>
        <li>KeyTick ist weder Ihnen noch Dritten gegenüber haftbar für Änderungen, Preisänderungen, die Sperrung oder Einstellung des Service.</li>
      </ol>
      <h2>12. Services Dritter</h2>
      <ol>
        <li>
          KeyTick kann Ihnen von Zeit zu Zeit Software, Anwendungen ("Apps"), Produkte, Services oder Website-Links von Dritten empfehlen, Ihnen den Zugriff
          darauf ermöglichen oder diese aktivieren (zusammen "Services Dritter"), damit Sie sie in Erwägung ziehen oder Verwenden. Diese Services Dritter werden
          Ihnen nur als Serviceleistung zur Verfügung gestellt, und wenn Sie diese Services Dritter kaufen, darauf zugreifen oder sie nutzen, erfolgt dies
          ausschließlich zwischen Ihnen und dem jeweiligen Drittanbieter ("Drittanbieter"). Zusätzlich zu diesen Allgemeinen Geschäftsbedingungen verpflichten
          Sie sich außerdem, an die zusätzlichen servicespezifischen Bedingungen gebunden zu sein, die für die von Ihnen von Drittanbietern erworbenen oder von
          diesen bereitgestellten Services gelten.
        </li>
        <li>
          Jegliche Nutzung von Services Dritter, erfolgt allein auf Ihr eigenes Risiko und nach Ihrem Ermessen, und es liegt in Ihrer Verantwortung, die für
          diese Services Dritter geltenden Bedingungen und/oder Datenschutzerklärungen zu lesen, bevor Sie die Services nutzen. In einigen Fällen kann KeyTick
          einen Umsatzanteil von Drittanbietern erhalten, die KeyTick Ihnen empfiehlt oder die Sie durch Ihre Nutzung der KeyTick-Website anderweitig
          beauftragen.
        </li>
        <li>
          Wir übernehmen keine Gewährleistungen und machen keine Zusicherungen im Hinblick auf Services Dritter. Sie erkennen an, dass KeyTick keine Kontrolle
          über Services Dritter hat und für diese Services Dritter weder Ihnen noch jemand anderem gegenüber verantwortlich oder haftbar ist. Die Verfügbarkeit
          von Services Dritter auf KeyTick-Websites, die Integration oder Aktivierung dieser Services Dritter mit den Services stellt weder eine Empfehlung,
          Autorisierung, finanzielle Unterstützung oder Verbindung von oder mit KeyTick dar noch deutet sie diese an. KeyTick übernimmt keine Garantie für die
          Verfügbarkeit von Services Dritter und Sie erkennen an, dass KeyTick den Zugriff auf Services Dritter jederzeit nach eigenem Ermessen und ohne
          Mitteilung an Sie deaktivieren kann. KeyTick ist niemandem gegenüber für die Einstellung oder der Sperrung des Zugriffs auf oder die Deaktivierung von
          Services Dritter verantwortlich oder haftbar. KeyTick empfiehlt Ihnen dringend, sich von Spezialisten beraten zu lassen, bevor Sie Services Dritter
          nutzen oder darauf zurückgreifen, damit Ihre Anforderungen erfüllt werden.
        </li>
        <li>
          Wenn Sie einen Service Dritter installieren oder zur Verwendung mit den Services aktivieren, erteilen Sie uns die Erlaubnis, dem jeweiligen
          Drittanbieter zu erlauben, auf Ihre Daten und andere Materialien zuzugreifen und sonstige Maßnahmen zu ergreifen, die für die Kompatibilität der
          Services Dritter mit den Services erforderlich sind, und jeglicher Austausch von Daten oder jede andere Interaktion zwischen Ihnen und dem
          Drittanbieter erfolgt ausschließlich zwischen Ihnen und diesem Drittanbieter. KeyTick ist nicht für Offenlegungen, Änderungen oder Löschungen Ihrer
          Daten oder anderer Materialien oder für entsprechende Verluste oder Schäden verantwortlich, die Sie infolge des Zugriffs eines Services Dritter oder
          eines Drittanbieters auf Ihre Daten oder andere Materialien erleiden.
        </li>
        <li>
          Google Maps ist ein Service Dritter, der innerhalb der Services genutzt wird. Für die Nutzung der Services müssen Sie die Google Maps and Earth
          Enterprise Universal Acceptable Use Policy, die zusätzlichen Nutzungsbedingungen für Google Maps/Google Earth und die Datenschutzerklärung von Google
          annehmen, die von Google gelegentlich geändert werden können.
        </li>
        <li>
          Die Beziehung zwischen Ihnen und Drittanbietern besteht ausschließlich zwischen Ihnen und diesem Drittanbieter, und KeyTick ist nicht verpflichtet,
          bei Streitigkeiten zwischen Ihnen und einem Drittanbieter zu intervenieren.
        </li>
        <li>
          Unter keinen Umständen haftet KeyTick für direkte, indirekte, zufällige, besondere, Folgeschäden, außerordentliche exemplarische Schäden, Strafschäden
          oder sonstige Schäden, die durch Services Dritter oder Ihre Vertragsbeziehung zu einem Drittanbieter, einschließlich Experten, entstehen. Diese
          Einschränkungen gelten auch dann, wenn KeyTick auf die Möglichkeit solcher Schäden hingewiesen wurde. Die vorstehenden Beschränkungen gelten im
          größtmöglichen gesetzlich zulässigen Umfang.
        </li>
        <li>
          Sie verpflichten sich, uns und (soweit anwendbar) unsere Muttergesellschaft, Tochtergesellschaften, Affiliates, KeyTick-Partner, leitenden
          Angestellten, Führungskräfte, Vertreter, Mitarbeiter in Bezug auf Ansprüche oder Forderungen (einschließlich angemessener Anwaltskosten) zu
          entschädigen und schadlos zu halten, die durch Ihre Nutzung eines Services Dritter oder Ihre Beziehung zu einem Drittanbieter entstehen.
        </li>
      </ol>
      <h2>13. Beta-Versionen</h2>
      <p>
        Von Zeit zu Zeit kann KeyTick Sie nach eigenem Ermessen dazu auffordern, versuchsweise Vorab- oder Beta-Funktionen zu nutzen, die sich noch in der
        Entwicklung befinden und noch nicht allen Händlern zur Verfügung stehen ("Beta-Versionen"). Möglicherweise gelten für Beta-Versionen zusätzliche
        Geschäftsbedingungen, die KeyTick Ihnen vor der Nutzung der Beta-Versionen bereitstellen wird. Solche Beta-Versionen und alle damit verbundenen
        Gespräche und Materialien werden als vertrauliche Informationen von KeyTick betrachtet und unterliegen den Vertraulichkeit Bestimmungen in dieser
        Vereinbarung. Ohne die Allgemeingültigkeit des Vorstehenden einzuschränken, verpflichten Sie sich, nur mit vorheriger schriftlicher Einwilligung von
        KeyTick öffentlichen Erklärungen zu Ihrer Teilnahme an den Beta-Versionen abzugeben oder anderweitig Informationen dazu offenzulegen. KeyTick gibt keine
        Zusicherungen oder Gewährleistungen, dass die Beta-Versionen funktionieren werden. KeyTick kann die Beta-Versionen nach eigenem Ermessen jederzeit
        einstellen. KeyTick übernimmt keinerlei Haftung für jegliche Schäden, die sich aus oder in Verbindung mit einer Beta-Version ergeben. Möglicherweise
        funktionieren die Beta-Versionen nicht genau wie in der endgültigen Version. KeyTick kann nach eigenem Ermessen eine endgültige oder kommerzielle
        Version der Beta-Versionen herausgeben.
      </p>
      <h2>14. Feedback und Bewertungen</h2>
      <p>
        KeyTick begrüßt Anregungen und/oder Vorschläge für Verbesserungen oder Ergänzungen unserer Services. Unter keinen Umständen unterliegen Mitteilungen von
        Anregungen, Vorschlägen oder zugehörigen Materialien oder Bewertungen der Services, der Services Dritter oder von Drittanbietern (zusammen "Feedback")
        an KeyTick einer Geheimhaltungspflicht oder sind mit einer erwarteten Vergütung verbunden. Indem Sie Feedback an KeyTick senden (ob direkt an KeyTick
        gesendet oder in einem von KeyTick gehosteten Forum oder auf einer von KeyTick gehosteten Seite veröffentlicht), verzichten Sie auf sämtliche Rechte an
        dem Feedback und erkennen an, dass es KeyTick freisteht, das Feedback auf Wunsch umzusetzen und zu verwenden, wie es von Ihnen bereitgestellt oder von
        KeyTick geändert wurde, ohne von Ihnen oder einem Dritten eine Genehmigung oder Lizenz einholen zu müssen. Bewertungen eines Services Dritter oder eines
        Drittanbieters, die Sie an KeyTick übermitteln, müssen nach Ihrem besten Wissen korrekt sein und dürfen weder rechtswidrig, obszön, drohend,
        verleumderisch, die Privatsphäre verletzend, Rechte an geistigem Eigentum verletzend oder anderweitig die Rechte Dritter verletzend oder unzulässig
        sein. KeyTick behält sich das Recht (jedoch nicht die Verpflichtung) vor, Feedback zu Services Dritter oder zu Drittanbietern zu entfernen oder zu
        bearbeiten, prüft veröffentlichtes Feedback jedoch nicht regelmäßig.
      </p>
      <h2>15. DMCA-Mitteilungs- und Löschverfahren</h2>
      <p>
        Keytick unterstützt den Schutz von geistigem Eigentum und fordert auch die KeyTick-Händler dazu auf. Es entspricht unserer Unternehmenspolitik, allen
        Hinweisen auf mutmaßliche Urheberrechtsverletzungen nachzugehen. Wenn jemand der Meinung ist, dass einer unserer Händler seine Rechte an geistigem
        Eigentum verletzt, kann er über E-Mail eine DMCA-Mitteilung an den von KeyTick angebotenen Support senden. Nach Erhalt einer DMCA-Mitteilung können wir
        die beanstandeten Materialien entfernen oder den Zugriff darauf deaktivieren. Sobald der Händler eine Mitteilung über die bevorstehende Löschung erhält,
        kann er über E-Mail an KeyTick Support eine Gegenmitteilung senden, wenn er Einspruch gegen die Beschwerde erhebt. Der ursprüngliche Beschwerdeführer
        hat nach Erhalt einer Gegenmitteilung 14 Werktage Zeit, um eine gerichtliche Anordnung zu erwirken, die den Händler daran hindert, die verletzende
        Tätigkeit auszuüben. Andernfalls stellen wir das Material wieder her.{" "}
      </p>
      <h2>16. Rechte Dritter</h2>
      <p>
        Außer für KeyTick und seine verbundenen Unternehmen, KeyTick-Benutzer oder Personen, die gemäß diesen Allgemeinen Geschäftsbedingungen auf
        KeyTick-Services zugreifen, hat keine natürliche oder juristische Person, die nicht Vertragspartei dieser Allgemeinen Geschäftsbedingungen ist, ein
        Recht zur Durchsetzung einer Bestimmung dieser Allgemeinen Geschäftsbedingungen, unabhängig davon, ob diese natürliche oder juristische Person
        namentlich, als Mitglied einer Klasse oder als Person identifiziert wurde, die einer bestimmten Beschreibung entspricht. Um Missverständnisse
        auszuschließen: Hiervon werden die Rechte eines zulässigen Rechtsnachfolgers oder Erwerbers dieser Bedingungen nicht berührt.
      </p>
      <h2>17. Datenschutz</h2>
      <p>
        KeyTick setzt sich intensiv für den Schutz Ihrer personenbezogenen Daten und der personenbezogenen Daten Ihrer Kunden ein. Durch Nutzung des Service
        erkennen Sie an und stimmen Sie zu, dass die Erfassung, Verwendung und Offenlegung dieser personenbezogenen Daten durch unsere Datenschutzerklärung
        geregelt wird.
      </p>
      <p>
        Darüber hinaus gilt Folgendes: Wenn Sie (a) im Europäischen Wirtschaftsraum (EWR) ansässig sind, (b) Sie Tickets oder Dienstleistungen an Kunden im EWR
        bereitstellen oder (c) Sie anderweitig den Anforderungen der EU-Datenschutz-Grundverordnung unterworfen sind, unterliegt die Erfassung und Nutzung
        personenbezogener Daten von europäischen Staatsbürgern durch KeyTick außerdem unserem Nachtrag zur Datenverarbeitung.
      </p>
    </LayoutFullPageSingel>
  );
}

export default TermsDash;
