import { Routes, Route } from "react-router-dom";
import Error404 from "../error404";
import CreateAccount from "./create";

function Account() {
  return (
    <Routes>
      <Route path="/create" element={<CreateAccount />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default Account;
