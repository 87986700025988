import LayoutFullPageSingel from "../shared/layout-fullpage-singel";

import { useNavigate, useLocation, Link } from "react-router-dom";
import style from "./login.module.css";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function Login() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ display: false, msg: "", link: undefined });
  const [email, setEmail] = useState({ text: "", error: false, helpMessage: "" });
  const [password, setPassword] = useState({ text: "", error: false, helpMessage: "" });
  let navigate = useNavigate();
  let location = useLocation();

  let state = location.state;
  let from = state ? state.from.pathname : "/";

  useEffect(() => {
    async function checkTokenValidation(token) {
      //Check if token is still valid
      let response = await fetch(process.env.REACT_APP_API + "/admin/account/authtest", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        navigate(from, { replace: true });
      } else {
        localStorage.removeItem("token");
      }
    }

    let token = localStorage.getItem("token");

    if (token) {
      checkTokenValidation(token);
    }
  }, [navigate, from]);

  useEffect(() => {
    async function getLoginAlert() {
      //Check if token is still valid
      let response = await fetch(process.env.REACT_APP_API + "/admin/global-alert", {
        method: "GET",
      });
      let loginAlert = (await response.json()).login;
      if (loginAlert.visible) {
        setAlert({ display: true, msg: loginAlert.massage, link: loginAlert.link });
      }
      if (!loginAlert.visible && alert.display) {
        setAlert({ display: false, msg: "", link: undefined });
      }
    }

    getLoginAlert();
  }, [alert.display]);

  return (
    <LayoutFullPageSingel
      banner={
        alert.display ? (
          <div>
            <p>{alert.msg}</p>
            {alert.link && <a href={alert.link}>Weitere Informationen</a>}
          </div>
        ) : undefined
      }
    >
      <div className={style.container}>
        <h1 className={style.title}>Willkommen</h1>
        <p className={style.content}>Bitte geben Sie Ihre Anmeldedaten ein, um Zugriff auf KeyTick zu bekommen.</p>

        <div className={style.form}>
          <TextField
            margin="normal"
            type="email"
            error={email.error}
            value={email.text}
            onChange={(event) => setEmail({ text: event.target.value, error: false, helpMessage: "" })}
            fullWidth
            label="E-Mail"
            helperText={email.helpMessage}
            variant="filled"
          />

          <TextField
            margin="normal"
            type="password"
            error={password.error}
            value={password.text}
            onChange={(event) => setPassword({ text: event.target.value, error: false, helpMessage: "" })}
            fullWidth
            label="Passwort"
            helperText={password.helpMessage}
            variant="filled"
          />
        </div>
        <div className={style.button}>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            disableElevation
            onClick={async () => {
              setLoading(true);
              try {
                let inputsFilled = true;
                if (!email.text) {
                  inputsFilled = false;
                  setEmail({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre E-Mail ein." });
                }
                if (!password.text) {
                  inputsFilled = false;
                  setPassword({ text: "", error: true, helpMessage: "Bitte geben Sie Ihr Passwort ein." });
                }
                if (inputsFilled) {
                  let response = await fetch(process.env.REACT_APP_API + "/admin/account/auth", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ eMail: email.text, password: password.text }),
                  });

                  if (response.status === 200) {
                    let data = await response.json();
                    if (data.status === "error") {
                      switch (data.code) {
                        case 1:
                          setEmail({ text: email.text, error: true, helpMessage: "Benutzerkonto nicht gefunden." });
                          break;
                        case 2:
                          setEmail({ text: email.text, error: true, helpMessage: "Anmeldedaten sind falsch." });
                          setPassword({ text: password.text, error: true, helpMessage: "Anmeldedaten sind falsch." });
                          break;
                        case 3:
                          setEmail({ text: email.text, error: true, helpMessage: "Bitte verifizieren Sie Ihr Konto mit dem Link in der Bestätigungs E-Mail." });
                          break;
                        default:
                          setEmail({ text: email.text, error: true, helpMessage: "Ein Server-Fehler ist aufgetreten." });
                          break;
                      }
                    }
                    if (data.status === "succesful") {
                      localStorage.setItem("token", data.token);
                      navigate(from, { replace: true });
                    }
                  }
                  if (response.status === 400) {
                    setEmail({ text: email.text, error: true, helpMessage: "Ein Server-Fehler ist aufgetreten." });
                  }
                }
              } catch (e) {
                console.error(e);
                setEmail({ text: email.text, error: true, helpMessage: "Ein Server-Fehler ist aufgetreten." });
              }
              setLoading(false);
            }}
          >
            Anmelden
          </LoadingButton>
        </div>
        <p className={style.additional}>
          Noch kein Benutzerkonto?{" "}
          <Link className={style.link} to="/account/create">
            Benutzerkonto erstellen
          </Link>
        </p>
      </div>
    </LayoutFullPageSingel>
  );
}

export default Login;
