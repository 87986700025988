import style from "./create.module.css";
import LayoutFullPageSingel from "../../shared/layout-fullpage-singel";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Checkbox, TextField } from "@mui/material";
import {CheckCircleTwoTone} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

function CreateAccount() {
  const [firstName, setFirstName] = useState({ text: "", error: false, helpMessage: "" });
  const [lastName, setLastName] = useState({ text: "", error: false, helpMessage: "" });
  const [birthday, setBirthday] = useState({ text: "", error: false, helpMessage: "" });
  const [email, setEmail] = useState({ text: "", error: false, helpMessage: "" });
  const [password, setPassword] = useState({ text: "", error: false, helpMessage: "" });
  const [secondPassword, setSecondPassword] = useState({ text: "", error: false, helpMessage: "" });
  const [terms, setTerms] = useState({ checked: false, error: false });
  const [state, setState] = useState("edit");
  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <h1 className={style.title}>Willkommen</h1>
        {state === "done" && (
          <div className={style.success}>
            <div>
              <CheckCircleTwoTone sx={{ color: "#52b788", fontSize: 80 }} />
            </div>
            <p>Eine Bestätigungsmail wurde an die angegebene E-Mail Adresse versendet. Bitte bestätigen Sie den Link in der E-Mail um fortzufahen.</p>
          </div>
        )}
        {state !== "done" && (
          <>
            <p className={style.content}>Für die Erstellung Ihres KeyTick Kontos werden noch ein paar Informationen benötigt.</p>
            <div className={style.form}>
              <TextField
                margin="normal"
                type="text"
                error={firstName.error}
                value={firstName.text}
                onChange={(event) => setFirstName({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Vorname"
                helperText={firstName.helpMessage}
                variant="filled"
              />
              <TextField
                margin="normal"
                type="text"
                error={lastName.error}
                value={lastName.text}
                onChange={(event) => setLastName({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Nachname"
                helperText={lastName.helpMessage}
                variant="filled"
              />
              <TextField
                margin="normal"
                value={birthday.text}
                error={birthday.error}
                onChange={(event) => {
                  setBirthday({ text: event.target.value, error: false, helpMessage: "" });
                }}
                fullWidth
                label="Geburtsdatum"
                type="date"
                helperText={birthday.helpMessage}
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="normal"
                type="email"
                error={email.error}
                value={email.text}
                onChange={(event) => setEmail({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="E-Mail"
                helperText={email.helpMessage}
                variant="filled"
              />

              <TextField
                margin="normal"
                type="password"
                error={password.error}
                value={password.text}
                onChange={(event) => setPassword({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Passwort"
                helperText={password.helpMessage}
                variant="filled"
              />

              <TextField
                margin="normal"
                type="password"
                error={secondPassword.error}
                value={secondPassword.text}
                onChange={(event) => setSecondPassword({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Passwort wiederholen"
                helperText={secondPassword.helpMessage}
                variant="filled"
              />

              <div className={style.legal}>
                <Checkbox
                  checked={terms.checked}
                  onChange={(event) => {
                    setTerms({ checked: event.target.checked, error: false, helpMessage: "" });
                  }}
                />
                <p>
                  Hiermit bestätige ich, dass ich die{" "}
                  <Link className={style.link} to="/legal/agb">
                    Allgemeine Geschäftsbedingungen
                  </Link>{" "}
                  und die{" "}
                  <Link className={style.link} to="/legal/datenschutz">
                    Datenschutzerklärung
                  </Link>{" "}
                  gelesen habe und diese akzeptiere.
                </p>
              </div>
            </div>
            <div className={style.button}>
              <LoadingButton
                loading={state === "loading"}
                variant="contained"
                size="large"
                disableElevation
                onClick={async () => {
                  setState("loading");

                  let informationOK = true;

                  //Check if all fields are filld
                  if (!firstName.text) {
                    informationOK = false;
                    setFirstName({ text: "", error: true, helpMessage: "Bitte geben Sie Ihren Vorname ein." });
                  }
                  if (!lastName.text) {
                    informationOK = false;
                    setLastName({ text: "", error: true, helpMessage: "Bitte geben Sie Ihren Nachname ein." });
                  }
                  if (!birthday.text) {
                    informationOK = false;
                    setBirthday({ text: "", error: true, helpMessage: "Bitte geben Sie Ihr Geburtstag ein." });
                  }
                  if (!email.text) {
                    informationOK = false;
                    setEmail({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre E-Mail ein." });
                  }
                  if (!password.text) {
                    informationOK = false;
                    setPassword({ text: "", error: true, helpMessage: "Bitte geben Sie ein starkes Passwort ein." });
                  }
                  if (!secondPassword.text) {
                    informationOK = false;
                    setSecondPassword({ text: "", error: true, helpMessage: "Bitte wiederholen Sie Ihr Passwort." });
                  } else {
                    if (password.text) {
                      if (password.text !== secondPassword.text) {
                        informationOK = false;
                        setSecondPassword({ text: secondPassword.text, error: true, helpMessage: "Die Passwörter stimmen nicht überein." });
                      }
                    }
                  }

                  if (!terms.checked) {
                    informationOK = false;
                    setTerms({ checked: false, error: true });
                  }

                  if (informationOK) {
                    try {
                      let response = await fetch(process.env.REACT_APP_API + "/admin/account/create", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          eMail: email.text,
                          password: password.text,
                          firstName: firstName.text,
                          lastName: lastName.text,
                          birthday: birthday.text,
                        }),
                      });

                      if (response.status === 200) {
                        let data = await response.json();

                        if (data.status === "error") {
                          switch (data.code) {
                            case 1:
                              setEmail({ text: email.text, error: true, helpMessage: "Diese E-Mail Adresse ist ungültig." });
                              break;
                            case 2:
                              setEmail({ text: email.text, error: true, helpMessage: "Es existiert bereits ein Account mit dieser E-Mail." });
                              break;
                            case 3:
                              setBirthday({
                                text: birthday.text,
                                error: true,
                                helpMessage: "Um ein Konto zu erstellen müssen Sie mindesten 18 Jahre alt sein.",
                              });
                              break;
                            default:
                              setEmail({ text: email.text, error: true, helpMessage: "Ein Server Fehler ist aufgetreten, bitte kontaktieren Sie den Support." });
                              break;
                          }
                          setState("edit");
                        }
                        if (data.status === "succesful") {
                          setState("done");
                        }
                      }
                      if (response.status === 400) {
                        setEmail({ text: email.text, error: true, helpMessage: "Ein Server Fehler ist aufgetreten, bitte kontaktieren Sie den Support." });
                        setState("edit");
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  } else {
                    setState("edit");
                  }
                }}
              >
                Regstieren
              </LoadingButton>
            </div>

            <p className={style.additional}>
              Benutzerkonto vorhanden?{" "}
              <Link className={style.link} to="/login">
                Melden Sie sich an
              </Link>
            </p>
          </>
        )}
      </div>
    </LayoutFullPageSingel>
  );
}

export default CreateAccount;
