import { Routes, Route } from "react-router-dom";
import Error404 from "../error404";
import Notice from "./notice";
import Privacy from "./privacy";
import TermsDash from "./terms-dash";
import TermsStore from "./terms-store";

function Legal() {
  return (
    <Routes>
      <Route path="/impressum" element={<Notice />} />
      <Route path="/agb" element={<TermsDash />} />
      <Route path="/agb-store" element={<TermsStore />} />
      <Route path="/datenschutz" element={<Privacy />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default Legal;
