import { AddTwoTone, EditTwoTone } from "@mui/icons-material";
import { Button, Divider, ListItemButton } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./events-view.module.css";

export default function EventsView() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [eventsInLoading, setEventsInLoading] = useState(true);
  async function loadEvents() {
    const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/event/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) {
      return;
    }

    setEvents(await response.json());
    setEventsInLoading(false);
  }

  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <DashCenterWrapper
      title="Events"
      end={
        <Button
          variant="contained"
          startIcon={<AddTwoTone />}
          disableElevation
          onClick={(e) => {
            e.preventDefault();
            localStorage.removeItem("editId");
            navigate("./create");
          }}
        >
          Hinzufügen
        </Button>
      }
    >
      <div className={style.main}>
        <div className={style.eventList}>
          <div className={style.eventListHeader}>
            <div className={style.itemIcon} style={{ opacity: 0 }} />
            <h2>Name</h2>
            <h2 style={{ textAlign: "right" }}>Preis</h2>
            <h2 style={{ textAlign: "right" }}>Verkauft</h2>
            <h2 style={{ textAlign: "right" }}>Datum</h2>
            <h2 style={{ textAlign: "right" }}></h2>
          </div>
          {events.map((event) => {
            return (
              <div key={event.eventID}>
                <Divider />
                <ListItemButton
                  component="div"
                  style={{ display: "block", padding: 0 }}
                  onClick={(e) => {
                    //  e.preventDefault();
                    navigate("./" + event.eventID);
                  }}
                >
                  <div className={style.eventListItem}>
                    <img alt="Event-Logo" src={"https://cdn.keytick.com/" + event.asset.logo} className={style.itemIcon} />
                    <p>{event.name}</p>
                    <p style={{ textAlign: "right" }}>{(event.tickets.price / 100).toFixed(2) + "€"}</p>
                    <p style={{ textAlign: "right" }}>{event.tickets.availibleInital - event.tickets.availible + "/" + event.tickets.availibleInital}</p>
                    <p style={{ textAlign: "right" }}>
                      {new Date(event.date).toLocaleString("de-DE", {
                        hour: "2-digit",
                        minute: "2-digit",
                        day: "numeric",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                    <EditTwoTone
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        localStorage.setItem("editId", event.eventID);
                        navigate("./create");
                      }}
                    />
                  </div>
                </ListItemButton>
              </div>
            );
          })}
          {!eventsInLoading && events.length === 0 && (
            <>
              <Divider />
              <p className={style.emptyState}>Keine Events gefunden.</p>
            </>
          )}
          {eventsInLoading && (
            <>
              <Divider />
              <p className={style.emptyState}>Events werden geladen.</p>
            </>
          )}
        </div>
      </div>
    </DashCenterWrapper>
  );
}
