import LayoutFullPageSingel from "../shared/layout-fullpage-singel";
import ErrorImage from "../assets/404.svg";
import style from "./error404.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Error404() {
  const navigate = useNavigate();
  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <img className={style.errorImage} src={ErrorImage} alt="logo" />
        <h1 className={style.title}>Seite nicht gefunden</h1>
        <p className={style.content}>
          Leider ist ein Fehler aufgetreten. Die gewünschte Seite konnte nicht gefunden werden. Wir entschuldigen uns vielmals hierfür. Bitte überprüfen Sie Ihre Eingabe oder Navigieren Sie zurück auf die Startseite.
        </p>
        <Button size="large" disableElevation variant="contained" onClick={() => navigate(`/`)}>
          Zur Hauptseite
        </Button>
      </div>
    </LayoutFullPageSingel>
  );
}

export default Error404;
