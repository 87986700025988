import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Legal from "./pages/legal/index";
import Support from "./pages/support";
import Login from "./pages/login";
import { createTheme, ThemeProvider } from "@mui/material";
import Home from "./pages/home";
import Verification from "./pages/verification";
import Account from "./pages/account";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#001e3c",
            },
            info: {
              main: "#001e3c",
            },
          },
          typography: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
          },
          shape: {
            borderRadius: "0.33rem",
          },
          components: {
            MuiButton: {
              styleOverrides: {
                sizeLarge: {
                  padding: "0.8rem 2rem",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  fontsize: "inherit",
                },
                sizeMedium: {
                  padding: "0.8rem 1.2rem",
                  fontWeight: "700",
                  fontFamily: "inherit",
                  fontsize: "inherit",
                },
              },
            },
            MuiFilledInput: {
              styleOverrides: {
                root: {
                  backgroundColor: "#f8f8f8",
                },
              },
            },
          },
        })}
      >
        <Routes>
          <Route path="/verification" element={<Verification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/support" element={<Support />} />
          <Route path="/legal/*" element={<Legal />} />
          <Route path="/account/*" element={<Account />} />
          <Route
            path="*"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

function RequireAuth({ children }) {
  let [isAuth, setIsAuth] = useState(true);

  let token = localStorage.getItem("token");
  let location = useLocation();

  useEffect(() => {
    async function checkTokenValidation(token) {
      //Check if token is still valid
      let response = await fetch(process.env.REACT_APP_API + "/admin/account/authtest", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status !== 200) {
        setIsAuth(false);
        localStorage.removeItem("token");
      }
    }

    if (isAuth) {
      if (!token) {
        setIsAuth(false);
        localStorage.removeItem("token");
      } else {
        checkTokenValidation(token);
      }
    }
  }, [isAuth, token]);

  if (isAuth) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
}
