import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import LayoutFullPageSingel from "../../shared/layout-fullpage-singel";
import style from "./create.module.css";

function OrganisationCreate() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState({ text: "", error: false, helpMessage: "" });
  const [email, setEmail] = useState({ text: "", error: false, helpMessage: "" });
  const [phone, setPhone] = useState({ text: "", error: false, helpMessage: "" });

  const [addressName, setAdressName] = useState({ text: "", error: false, helpMessage: "" });
  const [addressStreet, setAddressStreet] = useState({ text: "", error: false, helpMessage: "" });
  const [addressNumber, setAddressNumber] = useState({ text: "", error: false, helpMessage: "" });
  const [addressCity, setAddressCity] = useState({ text: "", error: false, helpMessage: "" });
  const [addressPlz, setAddressPlz] = useState({ text: "", error: false, helpMessage: "" });
  const [addressCountry, setAddressCountry] = useState({ text: "Deutschland", error: false, helpMessage: "" });

  const [bankName, setBankName] = useState({ text: "", error: false, helpMessage: "" });
  const [bankIBAN, setBankIBAN] = useState({ text: "", error: false, helpMessage: "" });
  const [terms, setTerms] = useState({ checked: false, error: false });

  if (name.error || email.error || phone.error) {
    if (step !== 1) {
      console.log("pass");
      setStep(1);
    }
  }

  if (addressName.error || addressNumber.error || addressPlz.error || addressStreet.error || addressCity.error || addressCountry.error) {
    if (step !== 2) {
      setStep(2);
    }
  }

  if (bankName.error || bankIBAN.error || terms.error) {
    if (step !== 3) {
      setStep(3);
    }
  }

  return (
    <LayoutFullPageSingel>
      <div className={style.container}>
        <div className={style.stepper}>
          <p className={style.stepperContent}>{`SCHRITT ${step} VON 3`}</p>
        </div>
        {step === 1 && (
          <>
            <h1 className={style.title}>INFOS</h1>
            <p className={style.content}>Bitte geben Sie die Informationen Ihrer Organisation und Kontaktmöglichkeiten ein.</p>

            <div className={style.form}>
              <TextField
                required
                margin="normal"
                type="text"
                error={name.error}
                value={name.text}
                onChange={(event) => setName({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Name"
                helperText={name.helpMessage}
                variant="filled"
              />

              <TextField
                required
                margin="normal"
                type="email"
                error={email.error}
                value={email.text}
                onChange={(event) => setEmail({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="E-Mail"
                helperText={email.helpMessage}
                variant="filled"
              />
              <TextField
                margin="normal"
                type="tel"
                error={phone.error}
                value={phone.text}
                onChange={(event) => setPhone({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Telefonnummer"
                helperText={phone.helpMessage}
                variant="filled"
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <h1 className={style.title}>Adresse</h1>
            <p className={style.content}>Bitte geben Sie Ihre Organisations Adresse ein.</p>

            <div className={style.form}>
              <TextField
                required
                margin="normal"
                type="text"
                error={addressName.error}
                value={addressName.text}
                onChange={(event) => setAdressName({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Name"
                helperText={addressName.helpMessage}
                variant="filled"
              />
              <div className={style.formSameRow}>
                <TextField
                  required
                  margin="normal"
                  type="text"
                  style={{ width: "200%" }}
                  error={addressStreet.error}
                  value={addressStreet.text}
                  onChange={(event) => setAddressStreet({ text: event.target.value, error: false, helpMessage: "" })}
                  label="Straße"
                  helperText={addressStreet.helpMessage}
                  variant="filled"
                />
                <TextField
                  required
                  margin="normal"
                  type="text"
                  error={addressNumber.error}
                  value={addressNumber.text}
                  onChange={(event) => setAddressNumber({ text: event.target.value, error: false, helpMessage: "" })}
                  label="Nr."
                  helperText={addressNumber.helpMessage}
                  variant="filled"
                />
              </div>
              <div className={style.formSameRow}>
                <TextField
                  required
                  margin="normal"
                  type="text"
                  style={{ width: "120%" }}
                  error={addressCity.error}
                  value={addressCity.text}
                  onChange={(event) => setAddressCity({ text: event.target.value, error: false, helpMessage: "" })}
                  label="Stadt"
                  helperText={addressCity.helpMessage}
                  variant="filled"
                />
                <TextField
                  required
                  margin="normal"
                  type="number"
                  error={addressPlz.error}
                  value={addressPlz.text}
                  onChange={(event) => setAddressPlz({ text: event.target.value, error: false, helpMessage: "" })}
                  label="PLZ"
                  helperText={addressPlz.helpMessage}
                  variant="filled"
                />
              </div>
              <TextField
                required
                margin="normal"
                type="text"
                error={addressCountry.error}
                value={addressCountry.text}
                onChange={(event) => setAddressCountry({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Land"
                helperText={addressCountry.helpMessage}
                variant="filled"
              />
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <h1 className={style.title}>Bankverbindung</h1>
            <p className={style.content}>Bitte geben Sie die Bankinformationen der Organisation ein.</p>
            <div className={style.form}>
              <TextField
                required
                margin="normal"
                type="text"
                error={bankName.error}
                value={bankName.text}
                onChange={(event) => setBankName({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="Name"
                helperText={bankName.helpMessage}
                variant="filled"
              />
              <TextField
                required
                margin="normal"
                type="text"
                error={bankIBAN.error}
                value={bankIBAN.text}
                onChange={(event) => setBankIBAN({ text: event.target.value, error: false, helpMessage: "" })}
                fullWidth
                label="IBAN"
                helperText={bankIBAN.helpMessage}
                variant="filled"
              />
              <div className={style.legal}>
                <Checkbox
                  checked={terms.checked}
                  onChange={(event) => {
                    setTerms({ checked: event.target.checked, error: false });
                  }}
                />
                <p>
                  Ich ermächtige KeyTick widerruflich, die von mir zu entrichtenden Zahlungen bei Fälligkeit durch Lastschrift von meinem Konto mit den zur
                  verfügung gestellten Daten abzubuchen.
                </p>
              </div>
            </div>
          </>
        )}

        <div className={style.button}>
          {step !== 1 && (
            <Button
              variant="text"
              size="large"
              disabled={loading}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Zurück
            </Button>
          )}
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            disableElevation
            onClick={async () => {
              setLoading(true);
              if (step === 1) {
                //Validate
                let informationOK = true;

                //Check if all fields are filld
                if (!name.text) {
                  informationOK = false;
                  setName({ text: "", error: true, helpMessage: "Bitte geben Sie Ihren Organisations Namen ein." });
                }
                if (!email.text) {
                  informationOK = false;
                  setEmail({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre E-Mail ein." });
                }
                if (informationOK) {
                  setStep(2);
                }
              }

              if (step === 2) {
                //Validate
                let informationOK = true;

                //Check if all fields are filld
                if (!addressName.text) {
                  informationOK = false;
                  setAdressName({ text: "", error: true, helpMessage: "Bitte geben Sie Ihren Namen ein." });
                }
                if (!addressStreet.text) {
                  informationOK = false;
                  setAddressStreet({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre Straße ein." });
                }
                if (!addressNumber.text) {
                  informationOK = false;
                  setAddressNumber({ text: "", error: true, helpMessage: "" });
                }
                if (!addressCity.text) {
                  informationOK = false;
                  setAddressCity({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre Stadt ein." });
                }
                if (!addressPlz.text) {
                  informationOK = false;
                  setAddressPlz({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre PLZ ein." });
                } else if (!/^\d+$/.test(addressPlz.text)) {
                  informationOK = false;
                  setAddressPlz({ text: addressPlz.text, error: true, helpMessage: "Bitte geben Sie eine gültige PLZ ein." });
                }

                if (!addressCountry.text) {
                  informationOK = false;
                  setAddressCountry({ text: "", error: true, helpMessage: "Bitte geben Sie Ihr Land ein." });
                } else if (addressCountry.text !== "Deutschland") {
                  informationOK = false;
                  setAddressCountry({
                    text: addressCountry.text,
                    error: true,
                    helpMessage: "Zurzeit kann KeyTick nur mit einer Deutschen Adresse genutzt werden.",
                  });
                }
                if (informationOK) {
                  setStep(3);
                }
              }

              if (step === 3) {
                //Validate
                let informationOK = true;

                //Check if all fields are filld
                if (!bankName.text) {
                  informationOK = false;
                  setBankName({ text: "", error: true, helpMessage: "Bitte geben Sie den Namen des Kontoinhabers ein." });
                }
                if (!bankIBAN.text) {
                  informationOK = false;
                  setBankIBAN({ text: "", error: true, helpMessage: "Bitte geben Sie Ihre IBAN ein." });
                } else if (bankIBAN.text.substring(0, 2).toUpperCase() !== "DE") {
                  informationOK = false;
                  setBankIBAN({ text: bankIBAN.text, error: true, helpMessage: "Bitte geben Sie eine Deutsche IBAN ein." });
                }

                if (!terms.checked) {
                  informationOK = false;
                  setBankIBAN({ text: bankIBAN.text, error: true, helpMessage: "Bitte akzeptieren Sie das SEPA Lastschriftmandat." });
                }

                if (informationOK) {
                  try {
                    let response = await fetch(process.env.REACT_APP_API + "/admin/organisation", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                      },
                      body: JSON.stringify({
                        organisationName: name.text,
                        organisationEmail: email.text,
                        address: {
                          name: addressName.text,
                          plz: addressPlz.text,
                          street: addressStreet.text,
                          nr: addressNumber.text,
                          city: addressCity.text,
                          country: addressCountry.text,
                        },
                        bank: {
                          accountHolder: bankName.text,
                          iban: bankIBAN.text,
                        },
                      }),
                    });
                    let feedback = await response.json();

                    if (feedback.status === "succesful") {
                      localStorage.setItem("organisation-id", feedback.id);
                      navigate("/", { replace: true });
                    } else if (feedback.status === "error") {
                      switch (feedback.code) {
                        case 1:
                          setEmail({ text: email.text, error: true, helpMessage: "Die E-Mail Adresse ist ungültig." });
                          break;
                        case 2:
                          setAdressName({ text: addressName.text, error: true, helpMessage: "Der Name darf keine Nummer enthalten." });
                          break;
                        case 3:
                          setAddressPlz({ text: addressPlz.text, error: true, helpMessage: "Die PLZ darf keine Buchstaben enthalten." });
                          break;
                        case 4:
                          setAddressStreet({ text: addressStreet.text, error: true, helpMessage: "Die Straße darf keine Nummer enthalten." });
                          break;
                        case 5:
                          //setStep(2);
                          //setAddressStreet({ text: addressStreet.text, error: true, helpMessage: "Die Straße darf keine Nummer enthalten" });
                          break;
                        case 6:
                          setAddressCity({ text: addressCity.text, error: true, helpMessage: "Die Stadt darf keine Nummer enthalten." });
                          break;
                        case 7:
                          setAddressCountry({ text: addressCountry.text, error: true, helpMessage: "Das Land darf keine Nummer enthalten." });
                          break;
                        case 8:
                          setBankIBAN({ text: bankIBAN.text, error: true, helpMessage: "Die IBAN ist ungültig." });
                          break;
                        case 9:
                          setBankIBAN({ text: bankIBAN.text, error: true, helpMessage: "Die IBAN ist ungültig." });
                          break;
                        case 10:
                          setBankIBAN({ text: bankIBAN.text, error: true, helpMessage: "Die IBAN ist ungültig." });
                          break;
                        default:
                          break;
                      }
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }
              }
              setLoading(false);
            }}
          >
            Weiter
          </LoadingButton>
        </div>
      </div>
    </LayoutFullPageSingel>
  );
}

export default OrganisationCreate;
