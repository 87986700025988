import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashCenterWrapper from "../components/dash-center-wrapper";
import style from "./events-detail.module.css";

export default function EventsDetail() {
  const { eventID } = useParams();

  return (
    <DashCenterWrapper title="Event">
      <div className={style.main}>
        <EventDetailCard id={eventID} />
      </div>
    </DashCenterWrapper>
  );
}

function EventDetailCard({ id }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});
  useEffect(() => {
    async function loadInfo() {
      const response = await fetch(process.env.REACT_APP_API + `/admin/${localStorage.getItem("organisation-id")}/event/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLoading(false);
      if (response.status !== 200) {
        return;
      }

      setInfo(await response.json());
    }
    loadInfo();
  }, [setInfo, id, setLoading]);
  console.log(info);
  return (
    <div className={style.card}>
      {loading && <p>Wird geladen...</p>}
      {info.id && !loading && (
        <div className={style.detail}>
          <h1>Details</h1>
          <Divider />

          <table className={style.eventInfo}>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{info.id}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{info.name}</td>
              </tr>
              <tr>
                <td>Logo</td>
                <td>
                  <img alt="Event-Logo" src={"https://cdn.keytick.com/" + info.logo} className={style.logo} />
                </td>
              </tr>
              <tr>
                <td>Beschreibung</td>
                <td>{info.description}</td>
              </tr>
              <tr>
                <td>Corona Regeln</td>
                <td>{info.coronaRule}</td>
              </tr>
              <tr>
                <td>Addresse verlangen</td>
                <td>{info.needAddress ? "Ja" : "Nein"}</td>
              </tr>
              <tr>
                <td>Veranstaltungs beginn</td>
                <td>{new Date(info.date).toLocaleString("de", { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" })}</td>
              </tr>
              <tr>
                <td>Verkaufs beginn</td>
                <td>
                  {new Date(info.dateSaleStart).toLocaleString("de", { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" })}
                </td>
              </tr>
              <tr>
                <td>Standort</td>
                <td>
                  {info.location.title}
                  <br />
                  {info.location.description}
                </td>
              </tr>
              <tr>
                <td>Preis pro Ticket</td>
                <td>{(info.price / 100).toFixed(2)}€</td>
              </tr>
              <tr>
                <td>Gebühr pro Ticket</td>
                <td>{(info.fee / 100).toFixed(2)}€</td>
              </tr>
              <tr>
                <td>Gewinn pro Ticket</td>
                <td>{((info.price - info.fee) / 100).toFixed(2)}€</td>
              </tr>
              <tr>
                <td>Ticket insgesamt</td>
                <td>{info.availibleInital}</td>
              </tr>
              <tr>
                <td>Ticket verfügbar</td>
                <td>{info.availible}</td>
              </tr>
              <tr>
                <td>Ticket verkauft</td>
                <td>{info.availibleInital - info.availible}</td>
              </tr>
              <tr>
                <td>Gesamt Umsatz</td>
                <td>{((info.price * (info.availibleInital - info.availible)) / 100).toFixed(2)}€</td>
              </tr>
              <tr>
                <td>Gesamt Gewinn</td>
                <td>{(((info.price - info.fee) * (info.availibleInital - info.availible)) / 100).toFixed(2)}€</td>
              </tr>
            </tbody>
          </table>

          <h1>Bestellungen</h1>
          <div className={style.responseivTable}>
            <div style={{ overflow: "hidden", minWidth: "40rem" }}>
              <Divider />
              <div className={style.membersList}>
                <h2>Name</h2>
                <h2>Bezahlt</h2>
                <h2>Status</h2>
                <h2 style={{ textAlign: "right" }}>Datum</h2>
              </div>
              {info.purchases.map((item) => {
                return (
                  <div key={item.id}>
                    <Divider />
                    <div
                      className={style.membersList}
                      onClick={() => {
                        navigate("/purchases/" + item.id);
                      }}
                    >
                      <p>{`${item.buyer.firstName} ${item.buyer.lastName}`}</p>
                      <p>{(item.price / 100).toFixed(2)}</p>
                      <p>{item.status}</p>
                      <p style={{ textAlign: "right" }}>{new Date(item.date).toLocaleString("de")}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {!info.id && !loading && <p>Event nicht gefunden.</p>}
    </div>
  );
}

/*

{info.purchases.map((item) => {
                return <div> </div>;
              })}
<div key={item.firstName + item.lastName + item.birthday}>
                    <Divider />
                    <div className={style.membersList}>
                      <p>{`${item.firstName} ${item.lastName}`}</p>
                      <p>{item.phone}</p>
                      <p>{new Date(item.birthday).toLocaleDateString("de-DE", { day: "2-digit", month: "long", year: "numeric" })}</p>
                      <p style={{ textAlign: "right" }}>{item.coronaStatus}</p>
                    </div>
                  </div></div>*/
