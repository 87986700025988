import LayoutFullPageSingel from "../../shared/layout-fullpage-singel";

function Notice() {
  return (
    <LayoutFullPageSingel>
      <h1>Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Myonit4o UG
        <br />
        Römerstraße 42
        <br />
        71155 Altdorf
      </p>

      <p>
        Handelsregister: 770981
        <br />
        Registergericht: HRB Stuttgart
      </p>

      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Benjamin Mondry
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 07031/6310766
        <br />
        E-Mail: info@myonit4o.com
      </p>

      <h2>Redaktionell verantwortlich</h2>
      <p>Benjamin Mondry</p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
        <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
      <p>
        Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zuständig ist die Universalschlichtungsstelle des Zentrums
        für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein (
        <a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">
          https://www.verbraucher-schlichter.de
        </a>
        ).
      </p>
    </LayoutFullPageSingel>
  );
}

export default Notice;
