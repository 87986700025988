import {
  AccountBalanceTwoTone,
  BusinessTwoTone,
  CloseTwoTone,
  InboxTwoTone,
  LocalActivityTwoTone,
  LogoutTwoTone,
  MenuTwoTone,
  NextWeekTwoTone,
  StorefrontTwoTone,
  SupportTwoTone,
} from "@mui/icons-material";
import { Collapse, Drawer, Grow, IconButton, Popper } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/KeyTick.svg";
import Footer from "../shared/footer";
import BalanceView from "./dash/balance/balance-view";
import Events from "./dash/events";
import EventsView from "./dash/events/events-view";
import Purchases from "./dash/purchases";
import StoreEdit from "./dash/store/store-edit";
import style from "./dashboard.module.css";
import Support from "./support";

function Dashboard() {
  const navigate = useNavigate();

  const [alert, setAlert] = useState({ display: false, dissmisable: undefined, msg: undefined, link: undefined });
  const [drawerState, setDrawerState] = useState({ open: false });
  const [organisation, setOrganisation] = useState(undefined);
  const [organisationContextMenu, setOrganisationContextMenu] = useState(null);

  useEffect(() => {
    async function getDashAlert() {
      let response = await fetch(process.env.REACT_APP_API + "/admin/global-alert", {
        method: "GET",
      });
      let dashAlert = (await response.json()).admin;
      //Check if dashAlert is alredy dismiss
      let dismissAlert = localStorage.getItem("adminDismissAlert");
      if (dismissAlert) {
        if (dismissAlert !== dashAlert.massage + dashAlert.link) {
          setAlert({ display: dashAlert.visible, dissmisable: dashAlert.dismissible, msg: dashAlert.massage, link: dashAlert.link });
        }
      } else {
        setAlert({ display: dashAlert.visible, dissmisable: dashAlert.dismissible, msg: dashAlert.massage, link: dashAlert.link });
      }
    }

    getDashAlert();
  }, [setAlert]);

  useEffect(() => {
    async function getOrganisation() {
      let response = await fetch(process.env.REACT_APP_API + "/admin/organisation", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let organisationList = await response.json();
      setOrganisation(organisationList.find((element) => element.organisationID === localStorage.getItem("organisation-id")));
    }

    getOrganisation();
  }, [setOrganisation]);

  const navContent = (
    <div className={style.nav}>
      <Link onClick={() => setDrawerState({ open: false })} to="/">
        <img className={style.navLogo} src={logo} alt="logo" />
      </Link>
      {/*<NavLink onClick={() => setDrawerState({ open: false })} to="/" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <HomeTwoTone color="inherit" className={style.navItemIcon} />
        <p>Home</p>
  </NavLink>*/}
      <NavLink onClick={() => setDrawerState({ open: false })} to="/events" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <LocalActivityTwoTone color="inherit" className={style.navItemIcon} />
        <p>Events</p>
      </NavLink>
      <NavLink onClick={() => setDrawerState({ open: false })} to="/purchases" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <InboxTwoTone color="inherit" className={style.navItemIcon} />
        <p>Bestellungen</p>
      </NavLink>
      <NavLink onClick={() => setDrawerState({ open: false })} to="/balance" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <AccountBalanceTwoTone color="inherit" className={style.navItemIcon} />
        <p>Kontostand</p>
      </NavLink>
      <NavLink onClick={() => setDrawerState({ open: false })} to="/store" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <StorefrontTwoTone color="inherit" className={style.navItemIcon} />
        <p>Store</p>
      </NavLink>
      {/* 
      <NavLink onClick={() => setDrawerState({ open: false })} to="/customers" className={({ isActive }) => (isActive ? style.navItemSelected : style.navItem)}>
        <PeopleTwoTone color="inherit" className={style.navItemIcon} />
        <p>Kuden</p>
      </NavLink>
     
  */}
    </div>
  );

  return (
    <div className={style.body}>
      <Collapse in={alert.display}>
        <div className={style.banner}>
          <p>
            {alert.msg}
            {"  "}
            {alert.link && <a href={alert.link}>Weiter Informationen</a>}
          </p>
          <IconButton
            color="inherit"
            onClick={() => {
              localStorage.setItem("adminDismissAlert", alert.msg + alert.link);
              setAlert({ display: false, dissmisable: undefined, msg: undefined, link: undefined });
            }}
          >
            <CloseTwoTone />
          </IconButton>
        </div>
      </Collapse>
      <Drawer
        variant="temporary"
        open={drawerState.open}
        onClose={() => setDrawerState({ open: !drawerState.open })}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "16rem" },
        }}
      >
        {navContent}
      </Drawer>

      <div className={style.grid}>
        <div className={style.navDesktop}>{navContent}</div>
        <div className={style.toolbar}>
          <div
            onClick={() => {
              setDrawerState({ open: !drawerState.open });
            }}
            className={style.toolbarHamburger}
          >
            <MenuTwoTone color="inherit" className={style.toolbarHamburgerIcon} />
          </div>
          <Link to="/">
            <img className={style.toolbarLogo} src={logo} alt="logo" />
          </Link>
          <div className={style.toolbarAction}>
            <div
              onClick={(event) => {
                setOrganisationContextMenu(organisationContextMenu ? null : event.currentTarget);
              }}
              className={style.toolbarOrganisation}
            >
              <BusinessTwoTone color="inherit" className={style.toolbarOrganisationIcon} />
              <p>{organisation && organisation.organisationName}</p>
            </div>
            <Popper open={organisationContextMenu != null} anchorEl={organisationContextMenu} placement={"bottom-end"} transition>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <div className={style.organisationContextMenu}>
                    <div
                      onClick={() => {
                        navigate("/organisations/", { replace: false });
                      }}
                      className={style.organisationContextMenuItem}
                    >
                      <NextWeekTwoTone color="inherit" className={style.organisationContextMenuItemIcon} />
                      <p>Organisation wechseln</p>
                    </div>
                    {/*<div className={style.organisationContextMenuItem}>
                  <p>Feedback geben</p>
            </div>*/}
                    <div className={style.organisationContextMenuItemDivider}></div>
                    <div
                      onClick={() => {
                        navigate("/support", { replace: false });
                      }}
                      className={style.organisationContextMenuItem}
                    >
                      <SupportTwoTone color="inherit" className={style.organisationContextMenuItemIcon} />
                      <p>Support (Hilfe)</p>
                    </div>
                    {/* Add later 
                <div className={style.organisationContextMenuItem}>
                      <p>Dunkelmodus</p>
                  </div>
                  <div className={style.organisationContextMenuItem}>
                      <p>Benutzerkonto Einstellungen</p> and Language Switcher
        </div>*/}
                    <div
                      onClick={() => {
                        localStorage.clear();
                        navigate("/", { replace: true });
                      }}
                      className={style.organisationContextMenuItem}
                    >
                      <LogoutTwoTone color="inherit" className={style.organisationContextMenuItemIconLogout} />
                      <p>Abmelden</p>
                    </div>
                  </div>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        <div className={style.main}>
          <div className={style.content}>
            <Routes>
              <Route index element={<Support />} />
              <Route path="/events/*" element={<Events />} />
              <Route path="/purchases/*" element={<Purchases />} />
              <Route path="/balance/*" element={<BalanceView />} />
              <Route path="/customers" element={<p>customers</p>} />
              <Route path="/store" element={<StoreEdit />} />
              <Route path="*" element={<h1>Error</h1>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
